import BlobReduce from "image-blob-reduce"

const getCanvasBlob = (canvas, type) => {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      console.log(blob)

      resolve(blob)
    }, type)
  })
}

const reduceBlob = async (canvas, type) => {
  const blob = await getCanvasBlob(canvas, type)
  const reducedBlob = await BlobReduce().toBlob(blob, { max: 600 })
  return reducedBlob
}

export { reduceBlob }
