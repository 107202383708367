<template>
  <notifications :duration="5000" close-on-click>
    <template #body="props">
      <v-card class="tw-m-4" :color="props.item.type">
        <v-card-title>
          <strong>
            {{ props.item.title }}
          </strong>
        </v-card-title>
        <v-card-text>
          <p class="text-body-1">
            {{ props.item.text }}
          </p>
        </v-card-text>
      </v-card>
    </template>
  </notifications>
</template>
