<template>
  <app-page title="Карты" double>
    <template #action>
      <div class="tw-w-full tw-px-4 md:tw-pb-4">
        <search-field
          class="tw-w-full"
          v-model="cardNumber"
          @submit="searchCard"
          :loading="loading"
          autoclear
        />
      </div>
    </template>
    <template #left>
      <card-data-block v-if="card" :card="card" />
      <div v-else>
        <alert-block
          class="tw-mb-4"
          text="Запросите у
      пользователя документы удостоверяющие личность."
        />
        <recent-search-block
          v-if="RecentSearchService.getCards().length > 0"
          :items="RecentSearchService.getCards()"
          @on-item-click="recentSearchCard"
        ></recent-search-block>
      </div>
    </template>
    <template #right v-if="card">
      <div class="tw-sticky tw-top-4">
        <card-action-block :card="card" @completed="refreshCard" />
        <card-extra-data-block
          v-if="validateRole('showTransactions')"
          :card="card"
        />
      </div>
    </template>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import AlertBlock from "@/components/AlertBlock.vue"
import SearchField from "@/components/SearchField.vue"
import CardDataBlock from "@/modules/cardwork/components/CardDataBlock.vue"
import CardActionBlock from "@/modules/cardwork/components/CardActionBlock.vue"
import CardExtraDataBlock from "@/modules/cardwork/components/CardExtraDataBlock.vue"
import RecentSearchBlock from "@/modules/cardwork/components/RecentSearchBlock.vue"
import RecentSearchService from "@/modules/cardwork/services/RecentSearchService"

import { getCard } from "@/modules/cardwork/services/CardService"
import { ref, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"
import { validateRole } from "@/utils/role-validator"

const router = useRouter()
const route = useRoute()

const cardNumber = ref("")
const card = ref(null)
const loading = ref(false)

const searchCard = async () => {
  loading.value = true
  try {
    card.value = await getCard(cardNumber.value)
    router.push(`/cards/${card.value.cardNumber}`)
    RecentSearchService.addCard(card.value.cardNumber)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const refreshCard = (cardNumberCallback) => {
  cardNumber.value = cardNumberCallback
  searchCard()
}

const recentSearchCard = (item) => {
  cardNumber.value = item
  searchCard()
}

onMounted(() => {
  const id = route.params.id
  if (id) {
    cardNumber.value = id
    searchCard()
  }
})
</script>
@/modules/cardwork/services/CardService
@/modules/cardwork/services/RecentSearchService
