<template>
  <app-page title="Новый пользователь" double>
    <template #header>
      <alert-block
        text="Запросите у
      пользователя документы удостоверяющие личность."
      />
    </template>
    <template #left>
      <v-card>
        <v-card-text class="border-b">
          <block-title title="Данные"></block-title>
        </v-card-text>
        <v-card-text>
          <age-warning-block
            v-if="personData.iin"
            :person="personData"
          ></age-warning-block>
          <v-text-field
            v-model="personData.iin"
            class="mb-4 tw-font-medium text-black"
            label="ИИН"
            density="compact"
            hide-details
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="personData.firstName"
            class="mb-4 tw-font-medium text-black"
            label="Имя"
            density="compact"
            hide-details
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="personData.lastName"
            class="mb-4 tw-font-medium text-black"
            label="Фамилия"
            density="compact"
            hide-details
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="personData.birthdate"
            class="mb-4 tw-font-medium text-black"
            label="Дата рождения"
            density="compact"
            hide-details
            variant="outlined"
            type="date"
          ></v-text-field>
          <v-select
            v-model="personData.genderCode"
            class="mb-4 tw-font-medium text-black"
            label="Пол"
            density="compact"
            hide-details
            variant="outlined"
            :items="genderCodes"
            item-title="title"
            item-value="value"
          ></v-select>
          <v-select
            v-model="personData.ascribedStatusCode"
            class="tw-font-medium text-black"
            label="Статус"
            density="compact"
            hide-details
            variant="outlined"
            :items="ascribedCodes"
            item-title="title"
            item-value="value"
          ></v-select>
        </v-card-text>
      </v-card>

      <!--  -->
    </template>
    <template #right>
      <v-card>
        <v-card-text>
          <alert-block
            class="tw-mb-4"
            text="Нажмите, чтобы создать пользователя"
          />
          <dialog-confirm-button
            color="purple"
            @confirm="createPersonAction"
            :disabled="isLoading"
            :loading="isLoading"
            block
            >Готово</dialog-confirm-button
          >
        </v-card-text>
      </v-card>
      <!--  -->
    </template>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import AlertBlock from "@/components/AlertBlock.vue"
import BlockTitle from "@/modules/cardwork/components/BlockTitle.vue"
import AgeWarningBlock from "@/modules/cardwork/components/AgeWarningBlock.vue"
import DialogConfirmButton from "@/components/DialogConfirmButton.vue"

import { ref } from "vue"
import { useRouter } from "vue-router"
import { formatDate } from "@/utils/date-utils"
import genderCodes from "@/modules/cardwork/utils/gender-code-list"
import ascribedCodes from "@/modules/cardwork/utils/ascribed-status-list"
import { useNotification } from "@kyvg/vue3-notification"
import { createPerson } from "@/modules/cardwork/services/PersonService"

const router = useRouter()
const { notify } = useNotification()
const personData = ref({
  iin: "",
  firstName: "",
  lastName: "",
  patronymic: "",
  genderCode: null,
  birthdate: formatDate(new Date(), "YYYY-MM-DD"),
  ascribedStatusCode: null,
})
const isLoading = ref(false)

const createPersonAction = async () => {
  try {
    isLoading.value = true
    const response = await createPerson(personData.value)
    notify({
      title: "Готово!",
      text: "Пользователь успешно создан",
      type: "green",
    })
    router.push(`/persons/${response.data.iin}`)
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
}
</script>
@/modules/cardwork/services/PersonService
@/modules/cardwork/utils/ascribed-status-list
