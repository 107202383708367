import { defineStore } from "pinia"
import { ref } from "vue"

export const usePageStore = defineStore("page", () => {
  const pageTitle = ref("")

  const changeTitle = (title) => {
    pageTitle.value = title || document.title
  }

  return { pageTitle, changeTitle }
})
