<template>
  <div class="tw-bg-slate-100">
    <div
      v-if="store.isAuthorized"
      class="tw-flex md:tw-flex-col tw-justify-center"
    >
      <div
        class="tw-hidden md:tw-hidden tw-col-span-9 tw-sticky tw-top-0 tw-z-50"
      >
        <top-bar />
      </div>

      <div
        class="tw-h-full tw-w-full tw-flex tw-max-w-[80rem] md:tw-max-w-full"
      >
        <div
          class="tw-w-max tw-min-w-80 tw-bg-gray-50 tw-border-l tw-border-r md:tw-col-span-9 md:tw-hidden"
        >
          <left-sidebar class="tw-block md:tw-hidden" />
        </div>
        <div
          class="tw-w-full tw-bg-gray-100 tw-border-r md:tw-col-span-9 md:tw-pb-20"
        >
          <main>
            <router-view class="md:tw-p-0"> </router-view>
          </main>
        </div>
      </div>
      <div
        class="tw-z-50 tw-hidden md:tw-block tw-fixed tw-bottom-0 tw-left-0 tw-right-0"
      >
        <bottom-bar />
      </div>
    </div>
    <div v-else class="tw-h-lvh">
      <router-view> </router-view>
    </div>
  </div>
  <app-notification />
</template>

<script setup>
import LeftSidebar from "@/components/LeftSidebar"
import TopBar from "@/components/mobile/TopBar"
import BottomBar from "@/components/mobile/BottomBar"
import AppNotification from "@/components/AppNotification.vue"
import { useUserStore } from "@/stores/user"

const store = useUserStore()
</script>
