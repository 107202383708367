<template>
  <p class="mb-4 text-body-1 text-grey">Мобильное приложение</p>
  <v-text-field
    v-model="mobileApplication.phone"
    class="mb-4 tw-font-medium text-grey"
    label="Телефон"
    density="compact"
    hide-details="auto"
    variant="outlined"
  ></v-text-field>
  <v-text-field
    v-model="mobileApplication.assignedDate"
    class="tw-font-medium text-grey"
    label="Дата привязки"
    density="compact"
    hide-details="auto"
    variant="outlined"
  ></v-text-field>
</template>

<script setup>
import { ref, defineProps } from "vue"

const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
})

const mobileApplication = ref(props.card.mobileApplication)
</script>
