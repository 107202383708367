<template>
  <alert-block
    v-for="(message, i) in getWarnings"
    :key="i"
    class="tw-mb-4 tw-bg-red-100 tw-border-red-200 tw-text-red-500"
    :text="message"
  />
</template>

<script setup>
import { computed, defineProps } from "vue"
import AgeChecker from "@/modules/cardwork/utils/age-checker"
import AlertBlock from "@/components/AlertBlock.vue"

const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
})

const getWarnings = computed(() => AgeChecker.getWarnings(props.person))
</script>
