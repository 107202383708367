import { createApp } from "vue"
import App from "@/App.vue"
import router from "@/router"
import vuetify from "@/plugins/vuetify"
import Notifications from "@kyvg/vue3-notification"
import { createPinia } from "pinia"
import "@/plugins/roboto-bold"
import "@/plugins/roboto-medium-normal"
import "@/assets/style.scss"
import AButton from "@/components/AButton.vue"

const pinia = createPinia()

createApp(App)
  .component("a-button", AButton)
  .use(vuetify)
  .use(router)
  .use(pinia)
  .use(Notifications)
  .mount("#app")
