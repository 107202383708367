import axiosInstance from "axios"
import { notify } from "@kyvg/vue3-notification"
import { useUserStore } from "@/stores/user"

const axiosClient = axiosInstance.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token")
    if (!token) {
      console.log("token not found in localstorage")
    }
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response)
  },
  (error) => {
    console.log(error)
    if (!error.response) {
      notify({
        title: "Ошибка!",
        text: "Проверьте подключение к интернету",
        type: "red",
      })
      return
    }
    if (error.response.status === 400) {
      notify({
        title: "Ошибка!",
        text: `Проверьте введенные данные и повторите попытку.`,
        type: "red",
      })
    } else if (error.response.status === 401) {
      notify({
        title: "Сеанс завершен",
        text: `Срок действия токена истек. Авторизуйтесь заново.`,
        type: "red",
      })
      const store = useUserStore()
      store.logout()
    } else if (error.response.status === 403) {
      notify({
        title: "Недостаточно прав!",
        type: "red",
      })
    } else if (error.response.status === 404) {
      notify({
        title: "Не найдено!",
        text: `Проверьте введенные данные и повторите попытку.`,
        type: "red",
      })
    } else if (error.response.status === 409) {
      notify({
        title: "Внимание!",
        text: `Карта не может быть выдана. Обратитесь в службу поддержки.`,
        type: "red",
      })
    } else {
      notify({
        title: "Ошибка!",
        text: `${error.response.status}`,
        type: "red",
      })
    }

    return Promise.reject(error)
  }
)

export default axiosClient
