import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import { aliases, mdi } from "vuetify/iconsets/mdi"
import "vuetify/styles"
import "@mdi/font/css/materialdesignicons.css"

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  defaults: {
    global: {
      ripple: false,
    },
    VCard: {
      elevation: 0,
      class: "custom-border ",
      rounded: "lg",
    },
    VDivier: {
      class: "custom-border",
    },
    VAvatar: {
      class: "custom-border rounded",
      rounded: 0,
    },
    VBtn: {
      class: "text-body-1",
      rounded: "lg",
    },
    VChip: {
      class: "text-body-1",
      rounded: "lg",
    },
    VAlert: {
      class: "text-body-1",
    },
    VTextField: {
      class: "text-body-1 ",
      rounded: "lg",
    },
    VListItemTitle: {
      class: "text-body-1",
    },
  },
})

export default vuetify
