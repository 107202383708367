<template>
  <app-page title="Отчет по сотрудникам">
    <div class="tw-flex tw-flex-col tw-gap-4">
      <date-picker
        v-model="dateRange"
        v-model:range="isRange"
        @updateRange="fetchRange"
        @updateSingle="fetchSingle"
        hourly
      />
      <v-progress-linear
        v-if="isLoading"
        class="rounded"
        height="6"
        color="primary"
        indeterminate
      />

      <div
        v-if="isRange"
        class="tw-snap-x tw-flex tw-gap-4 md:tw-overflow-x-auto md:tw-overflow-y-hidden md:no-scrollbar"
      >
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          v-if="!isLoading"
          :data="chartByDateFetch.data"
          x="dateConverted"
          y="count"
          title="Проверки"
          :colors="['#6200EE']"
        />
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          v-if="!isLoading"
          :data="chartByDateFetch.data"
          x="dateConverted"
          y="busCount"
          title="Автобусы"
          :colors="['#F9A825']"
        />
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          v-if="!isLoading"
          :data="chartByDateFetch.data"
          x="dateConverted"
          y="routeCount"
          title="Маршруты"
          :colors="['#2962FF']"
        />
      </div>

      <div
        v-else
        class="tw-snap-x tw-flex tw-gap-4 md:tw-overflow-x-auto md:tw-overflow-y-hidden md:no-scrollbar"
      >
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          v-if="!isLoading"
          :data="chartByHourFetch.data"
          x="hourConverted"
          y="count"
          title="Проверки"
          :colors="['#6200EE']"
        />
        <report-chart
          v-if="!isLoading"
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          :data="chartByHourFetch.data"
          x="hourConverted"
          y="busCount"
          title="Автобусы"
          :colors="['#F9A825']"
        />
        <report-chart
          v-if="!isLoading"
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          :data="chartByHourFetch.data"
          x="hourConverted"
          y="routeCount"
          title="Маршруты"
          :colors="['#2962FF']"
        />
      </div>
      <report-table
        v-if="!isLoading"
        :table-data="tableDataFetch.data"
        :fields="tableFields"
        @on-item-click="goToUserReport"
        filterable
        summaries
        exportTitle="Отчет КРС"
        exportSubtitle="Отчет по сотрудникам"
        :exportDescription="textDateRange"
      >
      </report-table>
    </div>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import DatePicker from "@/modules/ticketwork/components/DatePicker.vue"
import ReportChart from "@/modules/ticketwork/components/ReportChart.vue"
import ReportTable from "@/modules/ticketwork/components/ReportTable.vue"

import { ref, reactive } from "vue"
import { useRouter } from "vue-router"
import {
  getReportUsersByDate,
  getReportUsersByHour,
  getReportUsers,
} from "@/modules/ticketwork/services/KrsService"
import { useDateRange } from "@/composables/useDateRange"
import { useFetchData } from "@/composables/useFetchData"

const router = useRouter()
const { dateRange, isRange, textDateRange } = useDateRange()
const chartByDateFetch = reactive(useFetchData())
const chartByHourFetch = reactive(useFetchData())
const tableDataFetch = reactive(useFetchData())
const isLoading = ref(true)

const fetchRange = async () => {
  isLoading.value = true
  await Promise.all([
    chartByDateFetch.fetchData(getReportUsersByDate(dateRange.value)),
    tableDataFetch.fetchData(getReportUsers(dateRange.value)),
  ])
  isLoading.value = false
}

const fetchSingle = async () => {
  isLoading.value = true
  await Promise.all([
    chartByHourFetch.fetchData(getReportUsersByHour(dateRange.value)),
    tableDataFetch.fetchData(getReportUsers(dateRange.value)),
  ])
  isLoading.value = false
}

const goToUserReport = (item) => {
  router.push(
    `/krs/report/users/${item.iin}?from=${dateRange.value.from}&to=${dateRange.value.to}&name=${item.name}`
  )
}

const tableFields = ref({
  rows: [
    {
      columns: [
        {
          title: "Начало",
          field: "startTime",
          rowspan: 2,
          colspan: 1,
          type: "date",
          class: "tw-whitespace-nowrap tw-w-0 tw-font-medium tw-text-center ",
          list: {
            showLabel: false,
            class: "tw-order-1",
          },
        },
        {
          title: "Конец",
          field: "endTime",
          rowspan: 2,
          colspan: 1,
          type: "date",
          class: "tw-whitespace-nowrap tw-w-0 tw-font-medium tw-text-center ",
        },
        {
          title: "Имя",
          field: "name",
          rowspan: 2,
          colspan: 1,
          type: "text",
          class: "",
          filterable: true,
          list: {
            class: "tw-order-1",
          },
        },
        {
          title: "Количество",
          rowspan: 1,
          colspan: 5,
          type: "number",
        },
      ],
    },
    {
      columns: [
        {
          title: "Проверок",
          rowspan: 1,
          colspan: 3,
          type: "text",
          columns: [
            {
              title: "Общее",
              field: "count",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right ",
            },
            {
              title: "Успешные",
              field: "succesTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-green-600",
              export: {
                textColor: "#4CAF50",
              },
            },
            {
              title: "Неуспешные",
              field: "invalidTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-red-600",
              export: {
                textColor: "#F44336",
              },
            },
          ],
        },
        {
          title: "Автобусов",
          field: "busCount",
          rowspan: 1,
          colspan: 1,
          type: "number",
          class: "tw-text-right",
        },
        {
          title: "Маршрутов",
          field: "routeCount",
          rowspan: 1,
          colspan: 1,
          type: "number",
          class: "tw-text-right",
        },
      ],
    },
  ],
})
</script>
