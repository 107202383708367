import axios from "@/plugins/axios.js"

const login = async (iin, password) => {
  console.log(iin, password)
  try {
    const response = axios.post("login", {
      iin,
      password,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export { login }
