<template>
  <v-card class="mb-4">
    <v-card-text class="border-b">
      <p class="text-h6 tw-font-medium">Действия</p>
    </v-card-text>
    <v-card-text>
      <a-button
        class="mb-2"
        block
        @click="goToInstantCard"
        :disabled="!validateRole('instantCard')"
        >Выпуск карты
        <span class="material-symbols-outlined">open_in_new</span></a-button
      >
      <a-button
        block
        @click="goToAssignCard"
        :disabled="!validateRole('attachPerson')"
        >Привязать
        <span class="material-symbols-outlined">open_in_new</span></a-button
      >
    </v-card-text>
  </v-card>
</template>

<script setup>
import { defineProps } from "vue"
import { useRouter, useRoute } from "vue-router"
import { validateRole } from "@/utils/role-validator"

defineProps({
  person: {
    type: Object,
    required: true,
  },
})
const router = useRouter()
const route = useRoute()

const goToInstantCard = () => {
  router.push(route.path + "/create-instant-card")
}

const goToAssignCard = () => {
  router.push(route.path + "/attach")
}
</script>
