<template>
  <button
    class="tw-text-base tw-px-4 tw-py-2 tw-border tw-border-solid tw-rounded-lg tw-flex tw-gap-2 tw-items-center tw-justify-center tw-font-medium"
    :class="[
      block ? 'tw-block tw-w-full' : 'tw-inline-block',
      isDisabled
        ? `tw-bg-${color} tw-text-${color} tw-bg-${color}-100 tw-text-${color}-700 tw-border-${color}-200 tw-opacity-50`
        : `tw-bg-${color} tw-text-${color} tw-bg-${color}-100 tw-text-${color}-700 tw-border-${color}-200 hover:tw-brightness-95`,
    ]"
    @click="deleteUser"
    v-bind="$attrs"
  >
    <span
      v-if="isLoading"
      class="tw-block tw-w-full material-symbols-outlined tw-animate-spin"
      >progress_activity</span
    >

    <slot v-else></slot>
  </button>
</template>

<script setup>
import { computed, defineProps, useAttrs } from "vue"

defineProps({
  color: {
    type: String,
    default: "gray",
  },
  block: {
    type: Boolean,
    default: false,
  },
})

const attrs = useAttrs()
const isDisabled = computed(
  () => "disabled" in attrs && attrs.disabled !== false
)
const isLoading = computed(() => "loading" in attrs && attrs.loading !== false)

/* All supported classes for color props
    tw-bg-red-100 tw-border-red-200 tw-text-red-700
    tw-bg-purple-100 tw-border-purple-200 tw-text-purple-700
    tw-bg-green-100 tw-border-green-200 tw-text-green-700
    tw-bg-blue-100 tw-border-blue-200 tw-text-blue-700
    tw-bg-yellow-100 tw-border-yellow-200 tw-text-yellow-700
    tw-bg-emerald-100 tw-border-emerald-200 tw-text-emerald-700
    */
</script>
