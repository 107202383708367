<template>
  <div
    v-if="parsedRequestType.title !== ''"
    class="tw-flex tw-items-center tw-gap-2 tw-rounded tw-px-1 tw-whitespace-nowrap"
    :class="`tw-bg-${parsedRequestType.color}-50 tw-text-${parsedRequestType.color}-700`"
  >
    <span class="material-symbols-outlined tw-text-xl">{{
      parsedRequestType.icon
    }}</span>
    <span class="tw-font-medium">
      {{ parsedRequestType.title }}
    </span>
  </div>
  <div v-else class="tw-text-center">—</div>
</template>

<script setup>
import { computed, defineProps } from "vue"
import { formatRequestTypeMod } from "@/modules/ticketwork/services/KrsService"

const props = defineProps({
  requestType: {
    type: String,
    required: true,
  },
})

const parsedRequestType = computed(() =>
  formatRequestTypeMod(props.requestType)
)
</script>
