<template>
  <div class="tw-sticky tw-top-0 tw-bottom-0 tw-h-dvh">
    <div
      class="tw-overflow-auto no-scrollbar tw-h-full"
      @scroll="menuScrollListener"
    >
      <div class="tw-relative tw-flex tw-flex-col tw-h-full">
        <div class="tw-sticky tw-top-0 tw-z-50 tw-flex tw-flex-col">
          <div class="tw-bg-white tw-border-b">
            <div
              class="tw-px-4 tw-flex tw-gap-4 tw-items-center tw-border-b tw-cursor-pointer tw-p-4 hover:tw-opacity-90"
              @click="goHome()"
            >
              <div
                class="tw-p-1.5 tw-h-8 tw-w-8 tw-bg-purple-500 tw-border tw-border-purple-600 tw-rounded-md tw-transform hover:tw-scale-105 tw-transition-all"
              >
                <img src="/tolem-logo-only.png" alt="logo" />
              </div>
              <p class="tw-font-medium tw-text-2xl">Фронт Офис</p>
            </div>
            <div v-if="store.user" class="tw-p-4">
              <div class="tw-flex tw-gap-4">
                <div
                  class="tw-flex tw-flex-col tw-gap-1 tw-overflow-x-auto no-scrollbar"
                >
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <span class="material-symbols-outlined"
                      >account_circle</span
                    >
                    <span class="tw-font-medium tw-text-lg">
                      {{ store.user.iin }}
                    </span>
                  </div>

                  <div class="tw-flex tw-gap-2">
                    <div
                      v-for="role in userRoles"
                      :key="role"
                      class="tw-flex tw-gap-1 tw-items-center tw-rounded tw-bg-gray-100 tw-px-2 tw-text-gray-400"
                    >
                      <span class="tw-font-medium">{{ role }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            ref="sidebarTopShadow"
            class="tw-opacity-0 tw-absolute -tw-bottom-2 tw-left-0 tw-right-0 tw-bg-gradient-to-b tw-from-gray-300 tw-py-4 -tw-z-10 tw-rounded-b-lg tw-blur-sm"
          ></div>
        </div>

        <div class="tw-pb-8">
          <left-sidebar-section
            class="tw-border-b"
            v-if="validateRole('cardWork')"
            title="Работа с картами"
            :items="cardWorkItems"
          />
          <left-sidebar-section
            class="tw-border-b"
            v-if="validateRole('ticketWork')"
            title="Работа с КРС"
            :items="ticketWorkItems"
          />
        </div>
        <div class="tw-flex-grow"></div>
        <div
          class="tw-sticky tw-bottom-0 tw-px-2 tw-pb-2 tw-pt-2 tw-flex tw-flex-col tw-gap-2 tw-border-t tw-bg-white"
        >
          <button
            class="tw-w-full tw-px-2 tw-py-2 tw-font-medium tw-flex tw-items-center tw-gap-4 tw-rounded-lg hover:tw-bg-red-50 tw-z-50"
            @click="goToAuth"
          >
            <span class="material-symbols-outlined">logout</span>
            Завершить сеанс
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LeftSidebarSection from "@/components/LeftSidebar/LeftSidebarSection"
import { ref, computed, onMounted } from "vue"
import { useRouter } from "vue-router"
import { useUserStore } from "@/stores/user"
import { validateRole } from "@/utils/role-validator"

const store = useUserStore()
const router = useRouter()
const sidebarTopShadow = ref(null)

function goHome() {
  router.push("/")
}

function goToAuth() {
  router.push("/auth")
  store.logout()
}

const userRoles = computed(() =>
  store.user && store.user.roles ? store.user.roles : []
)

const menuScrollListener = (event) => {
  const scrollTop = event.target.scrollTop
  const scrollHeight = event.target.scrollHeight
  const currentScroll = scrollTop / scrollHeight
  sidebarTopShadow.value.style.opacity = currentScroll * 20
}

const cardWorkItems = ref([
  {
    subtitle: "Пользователи",
    allowed: true,
  },
  {
    id: 1,
    title: "Поиск по ИИН",
    icon: "person_search",
    path: "/persons",
    allowed: true,
  },
  {
    id: 2,
    title: "Создать",
    icon: "person_add",
    path: "/persons/create",
    allowed: true,
  },
  {
    subtitle: "Карты",
    allowed: true,
  },
  {
    id: 3,
    title: "Поиск по номеру",
    icon: "pageview",
    path: "/cards",
    allowed: true,
  },
  {
    id: 4,
    title: "Транзакции",
    icon: "table_chart",
    path: "/transactions",
    allowed: validateRole("showTransactions"),
  },
  {
    id: 5,
    title: "Мгновенный выпуск",
    icon: "print",
    path: "/cards/instant",
    allowed: true,
  },
])
const ticketWorkItems = ref([
  {
    subtitle: "Отчеты",
    allowed: true,
  },
  {
    id: 1,
    title: "По сотрудникам",
    icon: "group",
    path: "/krs/report/users",
    allowed: true,
  },
  {
    id: 2,
    title: "По автобусам",
    icon: "directions_bus",
    path: "/krs/report/buses",
    allowed: true,
  },
  {
    id: 3,
    title: "По маршрутам",
    icon: "route",
    path: "/krs/report/routes",
    allowed: true,
  },
  {
    subtitle: "Управление",
    allowed: true,
  },
  {
    id: 4,
    title: "Сотрудники",
    icon: "manage_accounts",
    path: "/krs/users",
    allowed: validateRole("viewKrsEmployers"),
  },
  {
    id: 5,
    title: "Печать бейджиков",
    icon: "print",
    path: "/krs/print",
    allowed: true,
  },
])

onMounted(() => {
  // if (validateRole("ticketWork")) {
  //   showCardWork.value = false
  //   showTicketWork.value = true
  // }
  // if (validateRole("cardWork")) {
  //   showCardWork.value = true
  //   showTicketWork.value = false
  // }
})
</script>
