<template>
  <app-page :title="title">
    <template #action>
      <div class="tw-flex-grow"></div>
      <div class="tw-px-4">
        <button
          class="tw-flex md:tw-hidden tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-bg-blue-50 tw-text-blue-600 tw-border tw-border-solid tw-border-blue-300 tw-font-medium tw-rounded-lg hover:tw-brightness-95"
          @click="goToLog"
        >
          Детальный лог
          <span class="material-symbols-outlined">list_alt</span>
        </button>
      </div>
    </template>
    <div class="tw-flex tw-flex-col tw-gap-4 md:tw-pb-14">
      <div
        class="tw-hidden md:tw-flex tw-z-10 tw-fixed tw-bottom-14 tw-left-0 tw-right-0 tw-gap-4 tw-p-4"
      >
        <button
          class="tw-flex md:tw-w-full tw-text-xl tw-justify-center tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-white tw-border tw-border-solid tw-border-blue-800 tw-font-medium tw-rounded-lg hover:tw-brightness-95 tw-shadow-md tw-transform active:tw-scale-95 tw-transition-all"
          @click="goToLog"
        >
          Детальный лог
          <span class="material-symbols-outlined">list_alt</span>
        </button>
      </div>
      <date-picker v-model="dateRange" @update="fetchData" hourly />
      <v-progress-linear
        v-if="isLoading"
        class="rounded"
        height="6"
        color="primary"
        indeterminate
      />

      <div
        v-if="!isLoading"
        class="tw-snap-x tw-flex tw-gap-4 md:tw-overflow-x-auto md:tw-overflow-y-hidden md:no-scrollbar"
      >
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          :data="items"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="count"
          title="Проверки"
          :colors="['#6200EE']"
        />
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          :data="items"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="busCount"
          title="Автобусы"
          :colors="['#F9A825']"
        />
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          :data="items"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="routeCount"
          title="Маршруты"
          :colors="['#2962FF']"
        />
      </div>
      <report-table
        v-if="!isLoading"
        :table-data="items"
        :fields="tableFields"
        :initial-sort-field="isRange ? 'date' : 'hour'"
        summaries
        :exportTitle="'Отчет КРС'"
        :exportSubtitle="title"
        :exportDescription="textDateRange"
      >
        <template v-if="!isRange" #busCount="{ item }">
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <div
                class="tw-flex tw-w-full tw-items-center tw-justify-end tw-gap-2 tw-text-gray-300 hover:tw-text-black"
                v-bind="props"
              >
                <span class="material-symbols-outlined">expand_more</span>
                <span class="tw-text-black">
                  {{ item.busCount }}
                </span>
              </div>
            </template>
            <div
              class="tw-w-64 tw-p-4 tw-flex tw-flex-wrap tw-gap-2 tw-bg-white tw-border tw-rounded-lg tw-shadow-lg"
            >
              <span class="tw-text-gray-600">({{ item.busList.length }}) </span>
              <router-link
                v-for="(bus, i) in item.busList"
                :key="i"
                :to="getUniqueBusURL(bus)"
                target="_blank"
              >
                <span
                  class="tw-border-b-2 tw-border-dotted tw-text-yellow-600 hover:tw-brightness-90"
                >
                  {{ bus }}<span v-if="i < item.busList.length - 1">, </span>
                </span>
              </router-link>
            </div>
          </v-menu>
        </template>
        <template v-if="!isRange" #routeCount="{ item }">
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <div
                class="tw-flex tw-w-full tw-items-center tw-justify-end tw-gap-2 tw-text-gray-300 hover:tw-text-black"
                v-bind="props"
              >
                <span class="material-symbols-outlined">expand_more</span>
                <span class="tw-text-black">
                  {{ item.routeCount }}
                </span>
              </div>
            </template>
            <div
              class="tw-w-64 tw-p-4 tw-flex tw-flex-wrap tw-gap-2 tw-bg-white tw-border tw-rounded-lg tw-shadow-lg"
            >
              <span class="tw-text-gray-600"
                >({{ item.routeList.length }})
              </span>
              <router-link
                v-for="(route, i) in item.routeList"
                :key="i"
                :to="getUniqueRouteURL(route)"
                target="_blank"
              >
                <span
                  class="tw-border-b-2 tw-border-dotted tw-text-blue-600 hover:tw-brightness-90"
                >
                  {{ route
                  }}<span v-if="i < item.routeList.length - 1">, </span>
                </span>
              </router-link>
            </div>
          </v-menu>
        </template>
      </report-table>
    </div>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import DatePicker from "@/modules/ticketwork/components/DatePicker.vue"
import ReportChart from "@/modules/ticketwork/components/ReportChart.vue"
import ReportTable from "@/modules/ticketwork/components/ReportTable.vue"

import { ref, computed, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import {
  getReportUsersByDateForUser,
  getReportUsersByHourForUser,
  getReportUserLog,
} from "@/modules/ticketwork/services/KrsService"
import { formatDate } from "@/utils/date-utils"
import {
  addLocalOffset,
  convertToHours,
  convertToHour,
  getHourFromDate,
} from "@/utils/date-utils"
import { useDateRange } from "@/composables/useDateRange.js"
import { usePageStore } from "@/stores/page"

const title = ref("")
const route = useRoute()
const router = useRouter()
const items = ref([])
const isLoading = ref(true)
const { dateRange, isRange, textDateRange } = useDateRange()

const { changeTitle } = usePageStore()

const fetchDataByDays = async () => {
  try {
    items.value = []
    const id = route.params.id
    if (!id) {
      return
    }
    const from = dateRange.value.from
    const to = dateRange.value.to
    const response = await getReportUsersByDateForUser(from, to, id)
    items.value = response.data

    items.value.sort((a, b) => new Date(a.date) - new Date(b.date))
    items.value.forEach((item) => {
      item.dateConverted = formatDate(item.date, "DD MMM")
      item.successTicketCount = item.count - item.invalidTicketCount
    })
  } catch (error) {
    console.log(error)
  }
}

const fetchDataByHours = async () => {
  try {
    items.value = []
    const id = route.params.id
    if (!id) {
      return
    }
    const from = dateRange.value.from

    const response = await getReportUsersByHourForUser(from, id)

    items.value = response.data.sort((a, b) => a.hour - b.hour)
    items.value.forEach((item) => {
      item.hourConverted = convertToHour(item.hour)
      item.hoursConverted = convertToHours(item.hour)
      item.successTicketCount = item.count - item.invalidTicketCount
    })
    console.log(items.value)
  } catch (error) {
    console.log(error)
  }
}

const fetchDataByLogForHours = async () => {
  if (items.value.length === 0) {
    return
  }

  const id = route.params.id
  if (!id) {
    return
  }
  const from = dateRange.value.from
  const to = formatDate(from, "YYYY-MM-DD", { add: "24" })

  const data = await getReportUserLog(id, from, to)
  if (!data) {
    return
  }

  const logsByHour = {}
  data.forEach((logItem) => {
    const logHour = getHourFromDate(logItem.date)
    if (logsByHour[logHour]) {
      logsByHour[logHour].buses.add(logItem.busRegistrationNumber)
      if (logItem.ticket && logItem.ticket.route) {
        logsByHour[logHour].routes.add(logItem.ticket.route)
      }
    } else {
      logsByHour[logHour] = { buses: new Set(), routes: new Set() }
    }
  })

  items.value.forEach((item) => {
    const localHour = addLocalOffset(item.hour)
    const busSet = logsByHour[localHour].buses
    const routeSet = logsByHour[localHour].routes

    item.busList = Array.from(busSet)
    item.routeList = Array.from(routeSet)
  })
}

const fetchData = async (isDayly) => {
  items.value = []
  isRange.value = isDayly
  isLoading.value = true
  if (isDayly) {
    await fetchDataByDays()
  } else {
    await fetchDataByHours()
    await fetchDataByLogForHours()
  }
  isLoading.value = false
}

const getUniqueBusURL = (id) =>
  `/krs/report/buses/${id}?from=${dateRange.value.from}&to=${dateRange.value.to}`
const getUniqueRouteURL = (id) =>
  `/krs/report/routes/${id}?from=${dateRange.value.from}&to=${dateRange.value.to}`

const goToLog = () => {
  const id = route.params.id
  const name = route.query.name
  if (id) {
    router.push(
      `/krs/report/users/log/${id}?from=${dateRange.value.from}&to=${dateRange.value.to}&name=${name}`
    )
  }
}

onMounted(async () => {
  const id = route.params.id
  const name = route.query.name

  if (name) {
    title.value = name
  }
  if (id) {
    title.value = title.value + " — " + id
  }
  changeTitle(title.value)
})

const tableFields = computed(() => ({
  rows: [
    {
      columns: [
        {
          title: isRange.value ? "Дата" : "Время",
          field: isRange.value ? "date" : "hoursConverted",
          sortField: isRange.value ? "date" : "hour",
          rowspan: 2,
          colspan: 1,
          type: isRange.value ? "onlyDate" : "string",
          class: "tw-whitespace-nowrap tw-font-medium tw-text-center tw-w-0",
        },
        {
          title: "Количество",
          rowspan: 1,
          colspan: 5,
          type: "number",
        },
      ],
    },
    {
      columns: [
        {
          title: "Проверок",
          rowspan: 1,
          colspan: 3,
          type: "text",
          columns: [
            {
              title: "Общее",
              field: "count",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right ",
            },
            {
              title: "Успешные",
              field: "successTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-green-600",
              export: {
                textColor: "#4CAF50",
              },
            },
            {
              title: "Неуспешные",
              field: "invalidTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-red-600",
              export: {
                textColor: "#F44336",
              },
            },
          ],
        },
        {
          title: "Автобусов",
          field: "busCount",
          rowspan: 1,
          colspan: 1,
          type: "number",
          class: "tw-text-right ",
        },
        {
          title: "Маршрутов",
          field: "routeCount",
          rowspan: 1,
          colspan: 1,
          type: "number",
          class: "tw-text-right",
        },
      ],
    },
  ],
}))
</script>
