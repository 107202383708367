<template>
  <p class="mb-4 text-body-1 text-grey">Информация от пользователя (STAGE)</p>
  <v-text-field
    v-model="stageData.firstName"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Имя"
    density="compact"
    hide-details="auto"
    variant="outlined"
    :readonly="!isEdit"
  ></v-text-field>
  <v-text-field
    v-model="stageData.lastName"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Фамилия"
    density="compact"
    hide-details="auto"
    variant="outlined"
    :readonly="!isEdit"
  ></v-text-field>
  <v-text-field
    v-model="stageData.birthdate"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Дата рождения"
    density="compact"
    type="date"
    hide-details="auto"
    variant="outlined"
    :readonly="!isEdit"
  ></v-text-field>
  <v-select
    v-model="stageData.genderCode"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Пол"
    density="compact"
    hide-details
    variant="outlined"
    :items="genderCodes"
    item-title="title"
    item-value="value"
    :readonly="!isEdit"
  ></v-select>
  <v-select
    v-model="stageData.ascribedStatusCode"
    class="tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Статус"
    density="compact"
    hide-details
    variant="outlined"
    :items="ascribedCodes"
    item-title="title"
    item-value="value"
    :readonly="!isEdit"
  ></v-select>
  <div class="mt-4" v-if="validateRole('editStage')">
    <a-button
      v-if="!isEdit"
      block
      :disabled="!validateRole('editStage')"
      @click="isEdit = true"
      ><span class="material-symbols-outlined">edit</span>
      Редактировать</a-button
    >

    <div v-else>
      <a-button
        class="mb-4"
        color="purple"
        block
        @click="updateStage()"
        :loading="isLoading"
        :disabled="isLoading"
        >Сохранить</a-button
      >
      <a-button block @click="cancelUpdateStageData()">Отмена</a-button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue"
import { validateRole } from "@/utils/role-validator"
import { useNotification } from "@kyvg/vue3-notification"
import ascribedCodes from "@/modules/cardwork/utils/ascribed-status-list"
import genderCodes from "@/modules/cardwork/utils/gender-code-list"
import { updateStageData } from "@/modules/cardwork/services/CardService"

const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
})

const { notify } = useNotification()

const stageData = computed(() => props.card.stageData)

let isEdit = ref(false)
let isLoading = ref(false)

function cancelUpdateStageData() {
  isLoading.value = false
  isEdit.value = false
}
const updateStage = async () => {
  isLoading.value = true
  try {
    await updateStageData(props.card)
    notify({
      title: "Готово!",
      text: "Данные успешно обновлены",
      type: "green",
    })
  } catch (error) {
    console.log(error)
  } finally {
    cancelUpdateStageData()
  }
}
</script>
