const getStatusData = (statusCode) => {
  if (statusCode === "ACTIVATED")
    return {
      title: "АКТИВИРОВАН",
      color: "green",
      background_color: "green-lighten-5",
      icon: "mdi-lock-open-outline",
    }
  if (statusCode === "BLOCKED")
    return {
      title: "ЗАБЛОКИРОВАН",
      color: "red",
      background_color: "red-lighten-5",
      icon: "mdi-lock-outline",
    }
  if (statusCode === "PRODUCING")
    return {
      title: "ВЫПУСКАЕТСЯ",
      color: "amber",
      background_color: "amber-lighten-5",
      icon: "mdi-clock-outline",
    }
  if (statusCode === "FLASHED")
    return {
      title: "ГОТОВА К ВЫДАЧЕ",
      color: "cyan",
      background_color: "cyan-lighten-5",
      icon: "mdi-arrow-right",
    }
  return {
    title: "НЕ ОПРЕДЕЛЕН",
    color: "grey",
    background_color: "grey-lighten-5",
    icon: "mdi-help",
  }
}

export default getStatusData
