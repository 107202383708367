<template>
  <header class="tw-h-max tw-bg-white tw-border-b">
    <div
      class="tw-px-4 tw-py-3 tw-flex tw-items-center tw-justify-between tw-gap-4"
    >
      <h1
        class="tw-absolute tw-pointer-events-none tw-left-0 tw-right-0 tw-px-16 tw-text-center tw-text-xl tw-font-medium tw-whitespace-nowrap tw-truncate"
      >
        {{ pageTitle }}
      </h1>
      <div
        class="tw-shrink-0 tw-p-1.5 tw-bg-purple-500 tw-border tw-border-purple-600 tw-rounded-lg tw-transform hover:tw-scale-105 tw-transition-all"
      >
        <img src="/tolem-logo-only.png" alt="logo" class="tw-w-4 tw-h-4" />
      </div>
      <!-- <button class="tw-flex tw-items-center" @click="onBackClick">
        <span class="material-symbols-outlined">arrow_back_ios</span>
      </button> -->
      <v-menu>
        <template v-slot:activator="{ props }">
          <button v-bind="props" class="tw-flex tw-items-center">
            <span class="material-symbols-outlined">more_vert</span>
          </button>
        </template>

        <div class="tw-py-2 tw-border tw-rounded-lg tw-bg-white">
          <ul>
            <li>
              <button class="tw-px-4 tw-py-2 tw-font-medium" @click="goToAuth">
                Завершить сеанс
              </button>
            </li>
          </ul>
        </div>
      </v-menu>
    </div>
  </header>
</template>

<script setup>
import { useUserStore } from "@/stores/user"
import { usePageStore } from "@/stores/page"
import { storeToRefs } from "pinia"
import { useRouter } from "vue-router"

const { pageTitle } = storeToRefs(usePageStore())

const { logout } = useUserStore()

const router = useRouter()

// const onBackClick = () => {
//   router.go(-1)
// }

const goToAuth = () => {
  router.push("/auth")
  logout()
}
</script>

<style lang="scss" scoped></style>
