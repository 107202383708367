export default {
  lastName: {
    type: "text",
    label: "Имя",
    x: 33,
    y: 9,
    size: 12,
  },
  firstName: {
    type: "text",
    label: "Фамилия",
    x: 33,
    y: 15.6,
    size: 12,
  },
  birthdate: {
    type: "text",
    label: "Дата рождения",
    x: 33,
    y: 22.4,
    size: 12,
  },
  cardNumber: {
    type: "text",
    label: "Номер карты",
    x: 7.2,
    y: 48.4,
    size: 12,
  },
  localPhotoUri: {
    type: "image",
    label: "Фото",
    path: "photo",
    x: 7.25,
    y: 6.5,
    width: 21,
    height: 28,
  },
  qrCode: {
    type: "image",
    label: "QR код",
    path: "qr",
    x: 58.0,
    y: 26.5,
    width: 20,
    height: 20,
  },
}
