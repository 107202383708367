import HomePage from "@/modules/cardwork/pages/HomePage.vue"
import PersonPage from "@/modules/cardwork/pages/PersonPage.vue"
import PersonCreatePage from "@/modules/cardwork/pages/PersonCreatePage.vue"
import AuthPage from "@/modules/cardwork/pages/AuthPage.vue"
import CardPage from "@/modules/cardwork/pages/CardPage.vue"
import InstantCardListPage from "@/modules/cardwork/pages/InstantCardListPage.vue"
import InstantCardCreatePage from "@/modules/cardwork/pages/InstantCardCreatePage.vue"
import TransactionPage from "@/modules/cardwork/pages/TransactionPage.vue"
import AttachPersonPage from "@/modules/cardwork/pages/AttachPersonPage.vue"

import * as Roles from "@/utils/role-validator"

const routes = [
  {
    path: "/",
    component: HomePage,
    redirect: "/persons",
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/persons",
    component: PersonPage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/persons/:id",
    component: PersonPage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/persons/create",
    component: PersonCreatePage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/cards",
    component: CardPage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/cards/:id",
    component: CardPage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/cards/instant",
    component: InstantCardListPage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/auth",
    component: AuthPage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/transactions",
    component: TransactionPage,
    meta: { roles: [Roles.CARDMASTER] },
  },
  {
    path: "/transactions/:id",
    component: TransactionPage,
    meta: { roles: [Roles.CARDMASTER] },
  },
  {
    path: "/persons/:id/create-instant-card",
    component: InstantCardCreatePage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
  {
    path: "/persons/:id/attach",
    component: AttachPersonPage,
    meta: { roles: [Roles.CARDMASTER, Roles.CARDISSUER, Roles.CARDOPERATOR] },
  },
]

export default routes
