export default (length = 5) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  const randomChars = []
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)
    const randomChar = charset[randomIndex]
    randomChars.push(randomChar)
    console.log(randomChar)
  }
  return randomChars.join("")
}
