<template>
  <app-page title="Сотрудник КРС" double>
    <template #header> </template>
    <template #left>
      <v-progress-linear
        v-if="isLoading"
        class="rounded"
        height="6"
        color="primary"
        indeterminate
      />
      <v-card v-if="krsUserData && !isLoading">
        <v-card-text class="border-b">
          <block-title title="Данные"></block-title>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="krsUserData.iin"
            type="text"
            class="mb-4 tw-font-medium"
            label="ИИН"
            density="compact"
            hide-details
            variant="outlined"
            maxlength="12"
            autocomplete="off"
            :disabled="!isNew"
          />
          <v-text-field
            v-model="krsUserData.name"
            type="text"
            class="mb-4 tw-font-medium"
            label="Имя"
            density="compact"
            hide-details
            variant="outlined"
            autocomplete="off"
            :disabled="!isEdit"
          />
          <v-text-field
            v-model="krsUserData.phone"
            type="text"
            class="mb-4 tw-font-medium"
            label="Телефон"
            density="compact"
            hide-details
            variant="outlined"
            autocomplete="off"
            name="name"
            maxlength="10"
            :disabled="!isEdit"
          />
          <v-text-field
            v-model="krsUserData.password"
            :type="isEdit ? 'text' : 'password'"
            class="mb-4 tw-font-medium"
            label="Пароль"
            hide-details
            density="compact"
            variant="outlined"
            maxlength="5"
            autocomplete="new-password"
            append-inner-icon="mdi-shuffle-variant"
            @click:append-inner="generateRandomPassword"
            :disabled="!isEdit"
          />
          <alert-block
            v-if="!isValidPassword && krsUserData.password"
            class="tw-mb-4 tw-bg-red-50 tw-text-red-500 tw-border-red-100"
            text="Длина пароля должна составлять ровно 5 символов"
          />

          <a-button v-if="!isEdit" @click="isEdit = true" block>
            <span class="material-symbols-outlined">edit</span>Редактировать
          </a-button>
          <div v-else>
            <div class="tw-flex tw-flex-col tw-gap-2">
              <a-button
                color="purple"
                :loading="isUpdateLoading"
                :disabled="isUpdateLoading"
                @click="updateUser"
                block
              >
                <span class="material-symbols-outlined">save</span>Сохранить
              </a-button>

              <dialog-confirm-button
                v-if="!isNew"
                color="red"
                @confirm="deleteUser"
                :loading="isDeleteLoading"
                :disabled="isDeleteLoading"
                block
                >Удалить сотрудника</dialog-confirm-button
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div v-if="!krsUserData" class="tw-text-xl tw-text-gray-400">
        Не удалось найти сотрудника:<br />
        {{ route.params.id }}
      </div>
    </template>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import AlertBlock from "@/components/AlertBlock.vue"
import BlockTitle from "@/modules/cardwork/components/BlockTitle.vue"
import DialogConfirmButton from "@/components/DialogConfirmButton.vue"
import { ref, computed, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"
import * as KrsService from "@/modules/ticketwork/services/KrsService"
import { useNotification } from "@kyvg/vue3-notification"
import generatePassword from "@/utils/password-generator"

const router = useRouter()
const route = useRoute()
const krsUserData = ref({
  iin: "",
  name: "",
  phone: "",
  city: "7252",
  password: "",
})

const isLoading = ref(false)
const isUpdateLoading = ref(false)
const isDeleteLoading = ref(false)
const isEdit = ref(false)
const isNew = ref(false)

const { notify } = useNotification()

const isValidPassword = computed(
  () => krsUserData.value.password && krsUserData.value.password.length === 5
)

const generateRandomPassword = () => {
  krsUserData.value.password = generatePassword(5)
}

const updateUser = async () => {
  try {
    isUpdateLoading.value = true

    if (isNew.value) {
      await KrsService.createUser(krsUserData.value)
      notify({
        title: "Готово!",
        text: "Создан новый сотрудник",
        type: "green",
      })
    } else {
      await KrsService.updateUser(krsUserData.value)
      notify({
        title: "Готово!",
        text: "Данные обновлены",
        type: "green",
      })
    }
    isEdit.value = false

    await fetchData()
  } catch (error) {
    console.error(error)
  } finally {
    isUpdateLoading.value = false
  }
}

const deleteUser = async () => {
  try {
    isDeleteLoading.value = true
    await KrsService.deleteUser(krsUserData.value.iin)
    notify({
      title: "Готово!",
      text: "Сотрудник удален",
      type: "green",
    })
    router.push("/krs/users")
  } catch (error) {
    console.error(error)
  } finally {
    isDeleteLoading.value = false
  }
}

const fetchData = async () => {
  try {
    isLoading.value = true
    const id = route.params.id
    if (id) {
      const response = await KrsService.getUser(id)
      krsUserData.value = response.data
    } else {
      isEdit.value = true
      isNew.value = true
    }
  } catch (error) {
    console.error(error)
    krsUserData.value = null
  } finally {
    isLoading.value = false
  }
}

onMounted(async () => {
  await fetchData()
})
</script>
