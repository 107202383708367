<template>
  <app-page title="Список сотрудников КРС">
    <template #action>
      <div class="tw-w-full tw-flex tw-gap-4 tw-px-4">
        <div class="tw-flex-grow md:tw-hidden"></div>
        <a-button class="tw-whitespace-nowrap md:tw-hidden" @click="goToAddUser"
          ><span class="material-symbols-outlined">add</span>Новый
          сотрудник</a-button
        >
      </div>
    </template>
    <v-progress-linear
      v-if="isLoading"
      class="rounded"
      height="6"
      color="primary"
      indeterminate
    />
    <report-table
      v-if="!isLoading"
      :table-data="data"
      :fields="tableFields"
      @on-item-click="onItemClick"
      filterable
      :exportable="false"
    >
      <template #goReport="{ item }">
        <button
          class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded hover:tw-text-white hover:tw-bg-purple-500"
          @click.stop="onReportClick(item.iin)"
        >
          <span class="material-symbols-outlined">insert_chart</span>
        </button>
      </template>
      <template #goPrint="{ item }">
        <button
          class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded hover:tw-text-white hover:tw-bg-purple-500"
          @click.stop="onPrintClick(item.iin)"
        >
          <span class="material-symbols-outlined">print</span>
        </button>
      </template>
    </report-table>
  </app-page>
</template>
<script setup>
import AppPage from "@/components/AppPage.vue"
import ReportTable from "@/modules/ticketwork/components/ReportTable.vue"
import { ref, onMounted } from "vue"
import { useRouter } from "vue-router"
import { getUsers } from "@/modules/ticketwork/services/KrsService"
import { useFetchData } from "@/composables/useFetchData"

const router = useRouter()
const { data, fetchData, isLoading } = useFetchData()

const onItemClick = (item) => {
  router.push(`/krs/users/${item.iin}`)
}

const onReportClick = (id) => {
  router.push(`/krs/report/users/${id}`)
}
const onPrintClick = (id) => {
  router.push(`/krs/print/${id}`)
}
const goToAddUser = () => {
  router.push(`/krs/users/create`)
}

onMounted(async () => {
  await fetchData(getUsers)
})

const tableFields = ref({
  rows: [
    {
      columns: [
        {
          title: "Имя",
          field: "name",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-font-medium tw-w-full",
          filterable: true,
        },
        {
          title: "ИИН",
          field: "iin",
          rowspan: 1,
          colspan: 1,
          type: "text ",
          class: "tw-text-right tw-font-mono",
          filterable: true,
        },
        {
          title: "Телефон",
          field: "phone",
          rowspan: 1,
          colspan: 1,
          type: "text ",
          class: "tw-text-right tw-font-mono",
          filterable: true,
        },
        {
          title: "Действия",
          rowspan: 1,
          colspan: 2,
          columns: [
            {
              field: "goReport",
            },
            {
              field: "goPrint",
            },
          ],
        },
      ],
    },
  ],
})
</script>

<style scoped></style>
