<template>
  <div>
    <slot name="button" :click="show">
      <a-button
        :color="color"
        @click="showDialog = true"
        :loading="loading"
        :disabled="loading"
        v-bind="$attrs"
      >
        <slot></slot>
      </a-button>
    </slot>
    <v-dialog v-model="showDialog" width="400">
      <div>
        <v-card variant="elevated">
          <v-card-text class="border-b">
            <p class="text-h6 tw-font-medium">Внимание</p>
          </v-card-text>

          <alert-block
            class="tw-rounded-none tw-border-none"
            text="Подтвердите действие"
          />
          <v-card-text>
            <a-button
              :color="color"
              :loading="loading"
              :disabled="loading"
              v-bind="$attrs"
              @click="confirmAction"
            >
              Подтвердить
            </a-button>
            <a-button
              block
              class="mt-4"
              color="gray"
              :loading="loading"
              :disabled="loading"
              @click=";(showDialog = false), (loading = false)"
            >
              Отмена
            </a-button>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue"
import AlertBlock from "@/components/AlertBlock.vue"

defineProps({
  color: {
    type: String,
    required: false,
  },
  icon: {
    type: String,
    required: false,
    default: "",
  },
})

const emit = defineEmits(["confirm"])

const loading = ref(false)
const showDialog = ref(false)

const show = () => {
  showDialog.value = true
}

const finishAction = () => {
  loading.value = false
  showDialog.value = false
  console.log("finish")
}

const confirmAction = () => {
  loading.value = true
  emit("confirm", finishAction)
}
</script>
