<template>
  <app-page :title="title">
    <div class="tw-flex tw-flex-col tw-gap-4">
      <date-picker v-model="dateRange" @update="fetchData" :single="!isRange" />
      <v-progress-linear
        v-if="isLoading"
        class="rounded"
        height="6"
        color="primary"
        indeterminate
      />

      <report-table
        v-if="!isLoading"
        :table-data="items"
        :fields="tableFields"
        filterable
        :exportTitle="'Отчет КРС'"
        :exportSubtitle="title"
        :exportDescription="textDateRange"
      >
        <template #type="{ item }">
          <event-type-badge :event-type="item.type" />
        </template>
        <template #request="{ item }">
          <request-type-badge :request-type="item.request.searchType" />
        </template>
        <template #error="{ item }">
          <log-result-badge :item="item" />
        </template>
      </report-table>
    </div>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import DatePicker from "@/modules/ticketwork/components/DatePicker.vue"
import ReportTable from "@/modules/ticketwork/components/ReportTable.vue"
import EventTypeBadge from "@/modules/ticketwork/components/EventTypeBadge.vue"
import RequestTypeBadge from "@/modules/ticketwork/components/RequestTypeBadge.vue"
import LogResultBadge from "@/modules/ticketwork/components/LogResultBadge.vue"

import { ref, computed, onMounted } from "vue"
import { useRoute } from "vue-router"
import { getReportRouteLog } from "@/modules/ticketwork/services/KrsService"
import { useDateRange } from "@/composables/useDateRange.js"
import { usePageStore } from "@/stores/page"

const title = ref("Маршрут")
const route = useRoute()
const items = ref([])
const isLoading = ref(true)
const { dateRange, isRange, textDateRange } = useDateRange()
const { changeTitle } = usePageStore()

const fetchLog = async () => {
  try {
    const id = route.params.id
    if (!id) {
      return
    }
    isLoading.value = true
    const from = dateRange.value.from
    const to = dateRange.value.to
    const data = await getReportRouteLog(id, from, to)
    items.value = data.sort((a, b) => a.count - b.count)
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const fetchData = async () => {
  await Promise.all([fetchLog()])
}

onMounted(async () => {
  const id = route.params.id
  if (id) {
    title.value = title.value + " — " + id
  }
  title.value = title.value + " — " + "Детальный лог"
  changeTitle(title.value)
})

const tableFields = computed(() => ({
  rows: [
    {
      columns: [
        {
          title: "Дата",
          field: "date",
          rowspan: 1,
          colspan: 1,
          type: "date",
          class: "tw-whitespace-nowrap tw-font-medium tw-text-center tw-w-0",
          export: {
            width: 30,
          },
        },
        {
          title: "Имя",
          field: "name",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: " tw-text-center",
          filterable: true,
        },
        {
          title: "ГРНЗ",
          field: "busRegistrationNumber",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-center",
          filterable: true,
          export: {
            width: 20,
          },
        },
        {
          tableOnly: true,
          title: "Тип",
          field: "type",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-left",
        },
        {
          exportOnly: true,
          title: "Тип события",
          field: "typeParsed",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-left",
        },
        {
          tableOnly: true,
          title: "Запрос",
          field: "request",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-left",
        },
        {
          exportOnly: true,
          title: "Запрос",
          field: "requestParsed",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-left",
        },
        {
          tableOnly: true,
          title: "Результат",
          field: "error",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-right tw-w-0 tw-whitespace-nowrap",
        },
        {
          exportOnly: true,
          title: "Результат",
          field: "ticketResult",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-right tw-w-0 tw-whitespace-nowrap",
        },
      ],
    },
  ],
}))
</script>
