<template>
  <header class="tw-h-max tw-bg-white tw-border-t">
    <div class="tw-py-2">
      <left-sidebar-section
        title="Действия"
        :items="ticketWorkItems"
        auto-expand
      />
    </div>
    <div class="tw-flex-grow"></div>
  </header>
</template>

<script setup>
import LeftSidebarSection from "@/components/LeftSidebar/LeftSidebarSection"
import { ref } from "vue"
import { validateRole } from "@/utils/role-validator"

const ticketWorkItems = ref([
  {
    subtitle: "Пользователи",
    allowed: validateRole("cardWork"),
  },
  {
    id: 1,
    title: "Поиск по ИИН",
    icon: "person_search",
    path: "/persons",
    allowed: validateRole("cardWork"),
  },
  {
    id: 2,
    title: "Создать",
    icon: "person_add",
    path: "/persons/create",
    allowed: validateRole("cardWork"),
  },
  {
    subtitle: "Карты",
    allowed: validateRole("cardWork"),
  },
  {
    id: 3,
    title: "Поиск по номеру",
    icon: "pageview",
    path: "/cards",
    allowed: validateRole("cardWork"),
  },
  {
    id: 4,
    title: "Транзакции",
    icon: "table_chart",
    path: "/transactions",
    allowed: validateRole("showTransactions") && validateRole("cardWork"),
  },
  {
    id: 5,
    title: "Мгновенный выпуск",
    icon: "print",
    path: "/cards/instant",
    allowed: validateRole("cardWork"),
  },
  {
    subtitle: "Отчеты",
    allowed: validateRole("ticketWork"),
  },
  {
    id: 6,
    title: "По сотрудникам",
    icon: "group",
    path: "/krs/report/users",
    allowed: validateRole("ticketWork"),
  },
  {
    id: 7,
    title: "По автобусам",
    icon: "directions_bus",
    path: "/krs/report/buses",
    allowed: validateRole("ticketWork"),
  },
  {
    id: 8,
    title: "По маршрутам",
    icon: "route",
    path: "/krs/report/routes",
    allowed: validateRole("ticketWork"),
  },
  {
    subtitle: "Управление",
    allowed: validateRole("ticketWork"),
  },
  {
    id: 9,
    title: "Сотрудники",
    icon: "manage_accounts",
    path: "/krs/users",
    allowed: validateRole("ticketWork"),
  },
  {
    id: 10,
    title: "Печать бейджиков",
    icon: "print",
    path: "/krs/print",
    allowed: validateRole("ticketWork"),
  },
  {
    subtitle: "Настройки",
    allowed: true,
  },
  {
    id: 11,
    title: "Завершить сеанс",
    icon: "logout",
    path: "/auth",
    allowed: true,
  },
])
</script>

<style lang="scss" scoped></style>
