<template>
  <div
    class="tw-flex tw-flex-col tw-gap-4 tw-bg-white tw-p-4 tw-border tw-rounded-lg"
  >
    <div v-if="hourly" class="tw-flex tw-gap-4">
      <button
        class="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-font-medium tw-rounded-lg hover:tw-brightness-95 md:tw-w-full"
        :class="
          isRange
            ? 'tw-bg-purple-50 tw-text-purple-600 tw-border tw-border-solid tw-border-purple-300 '
            : 'tw-bg-white tw-text-gray-600 tw-border tw-border-solid '
        "
        @click="updateDateRangeMode(true)"
      >
        <span class="material-symbols-outlined">calendar_month</span>
        По датам
      </button>
      <button
        class="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-font-medium tw-rounded-lg hover:tw-brightness-95 md:tw-w-full"
        :class="
          !isRange
            ? 'tw-bg-purple-50 tw-text-purple-600 tw-border tw-border-solid tw-border-purple-300 '
            : 'tw-bg-white tw-text-gray-600 tw-border tw-border-solid '
        "
        @click="updateDateRangeMode(false)"
      >
        <span class="material-symbols-outlined">schedule</span>
        По часам
      </button>
    </div>
    <div class="tw-flex tw-gap-4 md:tw-flex-col">
      <div
        class="tw-w-full tw-border tw-rounded-lg tw-flex tw-items-center tw-px-4 tw-py-2"
      >
        <input
          v-model="value.from"
          class="tw-w-full focus:tw-outline-none tw-font-medium"
          type="date"
        />
      </div>
      <div
        v-if="isRange"
        class="tw-w-full tw-border tw-rounded-lg tw-flex tw-items-center tw-px-4 tw-py-2"
      >
        <input
          v-model="value.to"
          class="tw-w-full focus:tw-outline-none tw-font-medium"
          type="date"
        />
      </div>
    </div>
    <div
      class="tw-w-full tw-whitespace-nowrap tw-flex tw-gap-2 md:tw-overflow-x-auto no-scrollbar"
    >
      <button
        class="tw-px-4 tw-py-2 tw-bg-gray-100 tw-font-medium tw-rounded-full hover:tw-brightness-95"
        label
        @click="fastDate()"
      >
        Сегодня
      </button>
      <button
        class="tw-px-4 tw-py-2 tw-bg-gray-100 tw-font-medium tw-rounded-full hover:tw-brightness-95"
        label
        @click="fastDate(1)"
      >
        Вчера
      </button>
      <button
        class="tw-px-4 tw-py-2 tw-bg-gray-100 tw-font-medium tw-rounded-full hover:tw-brightness-95"
        label
        @click="fastDate(7)"
      >
        Неделя
      </button>
      <button
        class="tw-px-4 tw-py-2 tw-bg-gray-100 tw-font-medium tw-rounded-full hover:tw-brightness-95"
        label
        @click="setCurrentMonth()"
      >
        Текущий месяц
      </button>
      <button
        class="tw-px-4 tw-py-2 tw-bg-gray-100 tw-font-medium tw-rounded-full hover:tw-brightness-95"
        label
        @click="setPreviousMonth()"
      >
        Прошлый месяц
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted } from "vue"
import { watchDebounced } from "@vueuse/core"
import { formatDate } from "@/utils/date-utils"
import { getDaysBetween } from "@/utils/date-utils"
import { useRoute } from "vue-router"

const route = useRoute()

const props = defineProps({
  modelValue: {},
  single: {
    type: Boolean,
  },
  hourly: {
    type: Boolean,
  },
})
const emit = defineEmits([
  "update:modelValue",
  "update:range",
  "update",
  "updateSingle",
  "updateRange",
])

const DATE_FORMAT = "YYYY-MM-DD"

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
  },
})

const isRange = ref(true)

const fastDate = (days = 0) => {
  value.value = {
    from: formatDate(new Date(), DATE_FORMAT, {
      substract: days,
    }),
    to:
      days === 0
        ? formatDate(new Date(), DATE_FORMAT, { add: "24" })
        : formatDate(new Date(), DATE_FORMAT),
  }
}

const setCurrentMonth = () => {
  const now = new Date()
  const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  value.value = {
    from: formatDate(firstDayOfCurrentMonth, DATE_FORMAT),
    to: formatDate(new Date(), DATE_FORMAT),
  }
}

const setPreviousMonth = () => {
  const now = new Date()
  const firstDayOfPreviousMonth = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    1
  )
  const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  value.value = {
    from: formatDate(firstDayOfPreviousMonth, DATE_FORMAT),
    to: formatDate(firstDayOfCurrentMonth, DATE_FORMAT),
  }
}

const updateDateRangeMode = (isRangeNew) => {
  isRange.value = isRangeNew
  emit("update", isRange.value)
  emit("update:range", isRange.value)
  if (isRange.value) {
    emit("updateRange")
  } else {
    emit("updateSingle")
  }
}

watchDebounced(
  () => value.value,
  async () => {
    if (props.hourly) {
      isRange.value = getDaysBetween(value.value.from, value.value.to) > 1
    }
    emit("update", isRange.value)
    emit("update:range", isRange.value)
    if (isRange.value) {
      emit("updateRange")
    } else {
      emit("updateSingle")
    }
  },
  { deep: true, debounce: 600, maxWait: 5000 }
)

onMounted(() => {
  const from = route.query.from
  const to = route.query.to

  if (from && to) {
    value.value.from = from
    value.value.to = to
  } else {
    fastDate()
  }
})
</script>
