import { defineStore } from "pinia"
import { ref, computed } from "vue"
import { login as loginTo } from "@/services/AuthService"
import { useRouter } from "vue-router"
import jwt_decode from "jwt-decode"

const KEY_USER = "user"
const KEY_ACCESS_TOKEN = "access_token"

export const useUserStore = defineStore("user", () => {
  const router = useRouter()
  const user = ref(JSON.parse(localStorage.getItem(KEY_USER)))
  const access_token = ref(localStorage.getItem(KEY_ACCESS_TOKEN))
  const isAuthorized = computed(() => access_token.value !== null)

  const login = async ({ iin, password }) => {
    try {
      const response = await loginTo(iin, password)
      if (!response.data.token) {
        throw new Error("Token is not found")
      }

      access_token.value = response.data.token
      localStorage.setItem(KEY_ACCESS_TOKEN, access_token.value)

      const decoded_token = jwt_decode(access_token.value)
      user.value = {
        roles: decoded_token.roles,
        iin: decoded_token.sub,
      }
      localStorage.setItem(KEY_USER, JSON.stringify(user.value))
      return Promise.resolve(user)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const logout = () => {
    localStorage.removeItem(KEY_ACCESS_TOKEN)
    localStorage.removeItem(KEY_USER)
    user.value = null
    access_token.value = null

    router.push("/auth")
  }

  return { user, access_token, isAuthorized, login, logout }
})
