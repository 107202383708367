<template>
  <div>
    <button
      class="tw-bg-white tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-4 tw-py-2 hover:tw-bg-gray-50 tw-transition-[border-radius]"
      :class="
        isExpanded
          ? ' tw-border-b tw-border-solid tw-rounded-b-none md:tw-border-none'
          : ' '
      "
      @click="isExpanded = !isExpanded"
    >
      <h1 class="tw-font-medium md:tw-text-xl">
        {{ getTitle }}
      </h1>
      <div class="tw-absolute tw-right-0 tw-px-4 tw-flex tw-items-center">
        <span
          class="material-symbols-outlined tw-transition-transform md:tw-text-3xl"
          :class="isExpanded ? 'tw-rotate-180' : ''"
        >
          expand_more
        </span>
      </div>
    </button>
    <div
      class="tw-overflow-auto tw-transition-[max-height] tw-ease-in-out tw-duration-150"
      :class="isExpanded ? 'tw-max-h-96' : 'tw-max-h-0'"
    >
      <ul class="tw-py-2 tw-flex tw-flex-col tw-bg-white tw-rounded-b-lg">
        <li v-for="item in items" :key="item.id">
          <p
            v-if="item.subtitle && item.allowed"
            class="tw-px-4 tw-text-gray-400"
          >
            {{ item.subtitle }}
          </p>

          <router-link
            v-if="!item.subtitle && item.allowed"
            :to="item.path"
            #="{ isActive }"
            @click="onClick(item)"
          >
            <div class="tw-w-full tw-px-2">
              <div
                class="tw-py-2 tw-px-2 tw-flex tw-items-center tw-gap-4 tw-font-medium tw-rounded-lg hover:tw-brightness-95 tw-transform active:tw-scale-95 tw-transition-all"
                :class="
                  isActive
                    ? 'tw-bg-purple-50 tw-text-purple-600'
                    : 'tw-bg-white tw-text-black'
                "
              >
                <span class="material-symbols-outlined">{{ item.icon }}</span>
                {{ item.title }}
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, computed } from "vue"

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: "",
  },
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
  autoExpand: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const isExpanded = ref(true)
const activeItemTitle = ref("")

const getTitle = computed(() => {
  if (activeItemTitle.value && props.autoExpand) {
    return activeItemTitle.value
  }
  return props.title
})

const onClick = (item) => {
  if (props.autoExpand) {
    isExpanded.value = false
  }
  activeItemTitle.value = item.title
}

onMounted(() => {
  if (props.autoExpand) {
    isExpanded.value = false
  }
})
</script>
