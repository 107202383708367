import moment from "moment"

export const addLocalOffset = (number) => {
  const timeOffset = moment().utcOffset() / 60
  const localHour = number + timeOffset
  return localHour
}
export const convertToHours = (number) => {
  const timeOffset = moment().utcOffset() / 60
  const localHour = number + timeOffset
  // const localHourPrev = localHour - 1
  const localHourNext = localHour + 1
  const hours = `${localHour}:00 - ${localHourNext}:00`

  return hours
}

export const convertToHour = (number) => {
  const timeOffset = moment().utcOffset() / 60
  const localHour = number + timeOffset
  const hours = `${localHour}:00`

  return hours
}

export const getDaysBetween = (date1, date2) => {
  const d1 = moment(date1)
  const d2 = moment(date2)
  return d2.diff(d1, "days")
}

export const getHourFromDate = (date) => {
  // return moment(date).utc().local().hour()
  return moment(date).hour()
}

export const formatDate = (
  inputDate,
  format = "DD.MM.YYYY",
  { substract = 0, add = 0 } = {}
) => {
  return moment(inputDate)
    .locale("ru")
    .utc()
    .local()
    .subtract(substract, "days")
    .add(add, "hours")
    .format(format)
}

export const substractHours = (time, hoursToSubtract = 1) => {
  const [hours, minutes, seconds] = time.split(":").map(Number)

  const date = new Date()

  date.setHours(hours, minutes, seconds)
  console.log(date)

  date.setHours(date.getHours() - hoursToSubtract)

  const resultHours = String(date.getHours()).padStart(2, "0")
  const resultMinutes = String(date.getMinutes()).padStart(2, "0")
  const resultSeconds = String(date.getSeconds()).padStart(2, "0")

  return `${resultHours}:${resultMinutes}:${resultSeconds}`
}
