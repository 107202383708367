<template>
  <div class="tw-b-red-100 tw-h-full tw-flex tw-items-center tw-justify-center">
    <div
      class="tw-block tw-p-6 tw-w-full tw-max-w-xs tw-border tw-border-solid tw-bg-white tw-rounded-lg"
    >
      <div class="mb-6 tw-flex tw-gap-4 tw-items-center tw-justify-center">
        <div
          class="tw-p-2 tw-bg-purple-500 tw-border tw-border-purple-600 tw-rounded-lg"
        >
          <img src="/tolem-logo-only.png" alt="logo" class="tw-w-5 tw-h-5" />
        </div>
        <h5 class="tw-text-2xl tw-font-medium">Фронт Офис</h5>
      </div>
      <v-form class="tw-flex tw-flex-col tw-gap-2" @submit.prevent="login()">
        <input
          v-model="authData.iin"
          class="tw-w-full focus:tw-outline-none tw-font-medium tw-border tw-border-solid tw-rounded-lg tw-px-4 tw-py-2 autofill:tw-bg-purple-200"
          placeholder="ИИН"
        />
        <input
          v-model="authData.password"
          class="tw-w-full focus:tw-outline-none tw-font-medium tw-border tw-border-solid tw-rounded-lg tw-px-4 tw-py-2"
          placeholder="Пароль"
          type="password"
        />
        <a-button
          class="tw-mt-4 tw-justify-between tw-bg-black tw-text-white tw-border-none tw-transform active:tw-scale-95 tw-transition-all"
          color="black"
          :disabled="isLoading"
          :loading="isLoading"
          >Войти
          <span class="material-symbols-outlined">login</span>
        </a-button>
      </v-form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { useUserStore } from "@/stores/user"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification"

const store = useUserStore()
const router = useRouter()
const authData = ref({ iin: "", password: "" })
const isLoading = ref(false)

const { notify } = useNotification()

const login = async () => {
  try {
    isLoading.value = true
    await store.login(authData.value)
    notify({
      title: "Вход в кабинет",
      text: `ИИН: ${store.user.iin}`,
      type: "primary",
    })
    router.push("/")
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}
</script>
