<template>
  <p class="mb-2 text-body-1 text-grey">Пользователь</p>
  <a-button block @click="goToPerson">
    {{ person.name + " " + person.surname }}
    <span class="material-symbols-outlined">open_in_new</span>
  </a-button>
</template>

<script setup>
import { useRouter } from "vue-router"
import { defineProps } from "vue"

const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
})

const router = useRouter()

const goToPerson = () => {
  router.push(`/persons/${props.person.iin}`)
}
</script>
