import { ref, computed } from "vue"
import { formatDate } from "@/utils/date-utils"

export function useDateRange() {
  const dateRange = ref({ from: "", to: "" })
  const isRange = ref(true)
  const textDateRange = computed(() => {
    const fromDateFormatted = formatDate(dateRange.value.from)
    const toDateFormatted = formatDate(dateRange.value.to)
    const multipleDaysText = `С ${fromDateFormatted} по ${toDateFormatted}`
    const oneDayText = `За ${fromDateFormatted}`
    const resultText = isRange.value ? multipleDaysText : oneDayText
    return resultText
  })

  return { dateRange, textDateRange, isRange }
}
