const RECENT_PERSONS = "recentSearchPersons"
const RECENT_CARDS = "recentSearchCards"
const RECENT_ITEM_COUNT = 4

const getPersons = () => {
  const items = JSON.parse(localStorage.getItem(RECENT_PERSONS))
  return items ? items.reverse() : []
}

const getCards = () => {
  const items = JSON.parse(localStorage.getItem(RECENT_CARDS))
  return items ? items.reverse() : []
}

const addPerson = (item) => {
  const items = getPersons()
  if (items) {
    if (items.indexOf(item) === -1) {
      const newItems = items.slice(-RECENT_ITEM_COUNT)
      newItems.push(item)
      localStorage.setItem(RECENT_PERSONS, JSON.stringify(newItems))
    }
  } else {
    localStorage.setItem(RECENT_PERSONS, JSON.stringify([item]))
  }
}

const addCard = (item) => {
  const items = localStorage.getItem(RECENT_CARDS)
  if (items) {
    if (items.indexOf(item) === -1) {
      const newItems = items.slice(-RECENT_ITEM_COUNT)
      newItems.push(item)
      localStorage.setItem(RECENT_CARDS, JSON.stringify(newItems))
    }
  } else {
    localStorage.setItem(RECENT_CARDS, JSON.stringify([item]))
  }
}

const clearHistory = () => {
  localStorage.removeItem(RECENT_CARDS)
  localStorage.removeItem(RECENT_PERSONS)
}

export default { addPerson, addCard, getPersons, getCards, clearHistory }
