<template>
  <div
    class="tw-flex tw-items-center tw-gap-2 tw-rounded tw-px-1 tw-whitespace-nowrap"
    :class="`tw-bg-${parsedEventType.color}-50 tw-text-${parsedEventType.color}-700`"
  >
    <span class="material-symbols-outlined tw-text-xl">{{
      parsedEventType.icon
    }}</span>
    <span class="tw-font-medium">
      {{ parsedEventType.title }}
    </span>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue"
import { formatEventTypeMod } from "@/modules/ticketwork/services/KrsService"

const props = defineProps({
  eventType: {
    type: String,
    required: true,
  },
})

const parsedEventType = computed(() => formatEventTypeMod(props.eventType))
</script>
