<template>
  <app-page title="Мгновенный выпуск карты" double>
    <template #header>
      <v-text-field
        v-model="personIIN"
        class="mb-4 tw-font-medium text-grey"
        label="ИИН"
        density="compact"
        hide-details
        variant="outlined"
        readonly
      ></v-text-field>
    </template>
    <template #left>
      <v-card>
        <v-card-text>
          <cropper
            v-if="inputPhoto"
            class="mb-4 cropper"
            ref="imageCropper"
            :src="inputPhotoWithoutBackground || inputPhoto"
            :stencil-props="stencilProps"
            :default-size="defaultSize"
            @ready="isPhotoNotApplied = false"
          />
          <alert-block
            v-else
            class="tw-mb-4"
            text="Проверьте данные и загрузите фотографию"
          />
          <v-file-input
            accept="image/*"
            variant="outlined"
            label="Прикрепить фотографию"
            density="compact"
            hide-details
            clearable
            prepend-icon=""
            prepend-inner-icon="mdi-attachment"
            show-size
            @change="onFileSelected"
            :disabled="isLoading.removeBackground"
            :loading="isLoading.removeBackground"
          ></v-file-input>
        </v-card-text>
      </v-card>
      <!--  -->
    </template>
    <template #right>
      <v-card>
        <v-card-text>
          <a-button
            class="mb-4"
            block
            @click="removePhotoBackground"
            :disabled="
              isLoading.removeBackground ||
              isLoading.createInstantCard ||
              isPhotoNotApplied
            "
            :loading="isLoading.removeBackground"
            >Удалить фон</a-button
          >
          <a-button
            class="mb-4"
            block
            @click="resetPhotoBackground"
            :disabled="
              isLoading.removeBackground ||
              isLoading.createInstantCard ||
              isPhotoNotApplied
            "
            >Вернуть оригинал</a-button
          >
          <dialog-confirm-button
            color="purple"
            @confirm="createPersonInstantCard"
            :disabled="
              isLoading.removeBackground ||
              isLoading.createInstantCard ||
              isPhotoNotApplied
            "
            :loading="isLoading.createInstantCard"
            block
            >Выпустить карту</dialog-confirm-button
          >
        </v-card-text>
      </v-card>
      <!--  -->
    </template>
  </app-page>
</template>
<script setup>
import AppPage from "@/components/AppPage.vue"
import AlertBlock from "@/components/AlertBlock.vue"
import DialogConfirmButton from "@/components/DialogConfirmButton.vue"
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"

import { ref, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification"
import {
  removeBackground,
  createInstantCard,
} from "@/modules/cardwork/services/CardService"
import { v4 as uuidv4 } from "uuid"
import { reduceBlob } from "@/utils/blob-maker"

const router = useRouter()
const route = useRoute()

const personIIN = ref(null)
const inputPhoto = ref(null)
const inputPhotoWithoutBackground = ref(null)
const imageCropper = ref(null)
const isPhotoNotApplied = ref(true)

const isLoading = ref({
  createInstantCard: false,
  removeBackground: false,
})

const { notify } = useNotification()

const defaultSize = ({ imageSize, visibleArea }) => {
  return {
    width: (visibleArea || imageSize).width,
    height: (visibleArea || imageSize).height,
  }
}

const stencilProps = {
  aspectRatio: 3 / 4,
}

const onFileSelected = (event) => {
  inputPhoto.value = URL.createObjectURL(event.target.files[0])
  resetPhotoBackground()
}
const removePhotoBackground = async () => {
  isLoading.value.removeBackground = true
  try {
    const { canvas } = imageCropper.value.getResult()
    const blob = await reduceBlob(canvas, "image/jpeg")
    const formData = new FormData()
    formData.append("file", blob)
    const response = await removeBackground(formData)
    inputPhotoWithoutBackground.value = URL.createObjectURL(response.data)
  } catch (error) {
    notify({
      title: "Ошибка!",
      text: `Повторите попытку. ${error.code}, ${error.response.status}`,
      type: "red",
    })
    console.log(error)
  } finally {
    isLoading.value.removeBackground = false
  }
}

const resetPhotoBackground = () => {
  inputPhotoWithoutBackground.value = null
}

const createPersonInstantCard = async (finishCallback) => {
  isLoading.value.createInstantCard = true
  const { canvas } = imageCropper.value.getResult()
  try {
    const blob = await reduceBlob(canvas, "image/png")
    console.log(blob)
    const formData = new FormData()
    formData.append("iin", personIIN.value)
    formData.append("file", blob)
    formData.append("requestId", uuidv4())

    const response = await createInstantCard(formData)
    console.log(response)
    notify({
      title: "Готово!",
      text: "Карта успешно выпущена",
      type: "green",
    })
    router.push(`/cards/instant`)
    console.log(router)
  } catch (error) {
    console.log("Create Instant Card", error)
  } finally {
    isLoading.value.createInstantCard = false
    finishCallback()
  }
}

onMounted(() => {
  const id = route.params.id
  if (id) {
    personIIN.value = id
  }
})
</script>

<style scoped>
.cropper {
  height: 400px;
  width: 400px;
}
</style>
@/modules/cardwork/services/CardService
