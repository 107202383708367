const parseCardNumber = (input) => {
  const regex = /^N:(\d+);ID:[A-F0-9]+;IIN:\d+$/
  const match = input.match(regex)
  if (match) {
    return match[1]
  }
  return ""
}
const parsePersonId = (input) => {
  const regex = /^N:\d+;ID:[A-F0-9]+;IIN:(\d+)$/
  const match = input.match(regex)
  if (match) {
    return match[1]
  }
  return ""
}
const isScanCode = (input) => {
  const regex = /^N:\d+;ID:[A-F0-9]+;IIN:\d+$/
  return regex.test(input)
}

const scanCardNumber = (input) => {
  let output = String(input).trim()
  if (isScanCode(output)) {
    output = String(parseCardNumber(output)).trim()
  }
  return output
}

const scanPersonId = (input) => {
  let output = String(input).trim()
  if (isScanCode(output)) {
    output = String(parsePersonId(output)).trim()
  }
  return output
}

export default { scanCardNumber, scanPersonId }
