<template>
  <form
    class="tw-relative md:tw-w-full tw-flex tw-items-center"
    @submit.prevent="submit"
  >
    <div
      class="tw-absolute tw-px-4 tw-top-0 tw-bottom-0 tw-right-0 tw-flex tw-items-center"
    >
      <label class="tw-flex tw-items-center" for="search-input">
        <span
          v-if="loading"
          class="material-symbols-outlined tw-text-purple-600 tw-animate-spin"
          >progress_activity</span
        >
        <button v-else @click="submit" class="tw-flex tw-items-center">
          <span class="material-symbols-outlined tw-text-gray-400">search</span>
        </button>
      </label>
    </div>

    <input
      v-model="value"
      ref="searchInput"
      id="search-input"
      class="tw-px-4 tw-py-2 tw-w-full tw-bg-white tw-border tw-border-gray-400 tw-border-solid tw-font-medium tw-rounded-lg focus:tw-outline-purple-600 focus:tw-bg-white disabled:tw-text-gray-400"
      placeholder="Поиск"
      :disabled="loading"
      @input="value = $event.target.value"
      v-bind="$attrs"
    />
  </form>
</template>

<script setup>
import {
  ref,
  computed,
  watch,
  defineEmits,
  defineProps,
  nextTick,
  onMounted,
} from "vue"

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  autoclear: {
    type: Boolean,
    default: false,
  },
  modelValue: {},
})

const emit = defineEmits(["submit", "update:modelValue"])
const searchInput = ref(null)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
  },
})

const submit = () => {
  emit("submit", value)

  if (props.autoclear) {
    value.value = ""
  }
}

// каждый раз когда изменяется loading
// ставим фокус на поле вводе::w
watch(
  () => props.loading,
  async () => {
    await nextTick()
    searchInput.value.focus()
  }
)

onMounted(async () => {
  await nextTick()
  searchInput.value.focus()
})
</script>
