<template>
  <v-card class="mb-4">
    <v-card-text class="border-b">
      <p class="text-h6 tw-font-medium">Действия с картой</p>
    </v-card-text>
    <v-card-text>
      <div class="tw-grid tw-grid-cols-4 tw-gap-4">
        <dialog-confirm-button
          v-for="(action, i) in allowedActions"
          :key="i"
          block
          @confirm="sendAction(action.action, $event)"
          :color="action.color"
        >
          <template #button="{ click }">
            <button
              class="tw-truncate tw-aspect-square tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-w-full hover:tw-brightness-95"
              :class="
                'tw-bg-' +
                action.color +
                '-50' +
                ' tw-text-' +
                action.color +
                '-500' +
                ' tw-border-' +
                action.color +
                '-300'
              "
              @click="click"
            >
              <span class="material-symbols-outlined tw-text-4xl">{{
                action.icon
              }}</span>
              <span
                class="xl:tw-hidden tw-font-medium tw-truncate tw-max-w-16 tw-text-sm"
                >{{ action.title }}</span
              >
            </button>
          </template>
          {{ action.title }}
        </dialog-confirm-button>
        <button
          v-for="i in unallowedActionsCount"
          :key="i"
          class="tw-truncate tw-bg-gray-50 tw-aspect-square tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-w-full"
          disabled
        ></button>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue"
import { validateRole } from "@/utils/role-validator"
import { useNotification } from "@kyvg/vue3-notification"
import DialogConfirmButton from "@/components/DialogConfirmButton.vue"

import {
  handoverCard,
  activateCard,
  blockCard,
  unassignCard,
} from "@/modules/cardwork/services/CardService"

const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(["completed"])

// tw-bg-purple-50 tw-text-purple-500
// tw-bg-green-50 tw-text-green-500
// tw-bg-red-50 tw-text-red-500
// tw-bg-blue-50 tw-text-blue-500

const cardActions = ref([
  {
    actionName: "handoverCard",
    action: handoverCard,
    title: "Выдача",
    color: "purple",
    check: true,
    icon: "approval_delegation",
  },
  {
    actionName: "activateCard",
    action: activateCard,
    title: "Активация",
    color: "green",
    check: true,
    icon: "lock_open",
  },
  {
    actionName: "blockCard",
    action: blockCard,
    title: "Блокировка",
    color: "red",
    check: props.card.statusCode !== "FLASHED",
    icon: "lock",
  },
  {
    actionName: "unassignCard",
    action: unassignCard,
    title: "Отвязка",
    color: "blue",
    check:
      props.card.hasMobileApplication &&
      props.card.mobileApplication.phone === "+77017641082",
    icon: "link_off",
  },
])

const allowedActions = computed(() =>
  cardActions.value.filter(
    (item) => validateRole(item.actionName) && item.check
  )
)

const unallowedActionsCount = computed(
  () => cardActions.value.length - allowedActions.value.length
)

console.log(allowedActions.value)
const { notify } = useNotification()
const loading = ref(false)

const sendAction = async (action, finishCallback) => {
  try {
    loading.value = true
    const response = await action(props.card)
    notify({
      title: "Готово!",
      text: "Действие выполнено",
      type: "green",
    })
    console.log(response)
    emit("completed", props.card.cardNumber)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
    finishCallback()
  }
}
</script>
@/modules/cardwork/services/CardService
