import { jsPDF } from "jspdf"
import { getImage } from "@/modules/cardwork/services/CardService"
import { formatCardNumber } from "@/modules/cardwork/utils/card-number-formatter"

const ORIENTATION = "l"
const SIZE_DIMENSION = "mm"
const PAPER_SIZE = [86, 54]

const generatePrint = async (data, template) => {
  try {
    const doc = new jsPDF(ORIENTATION, SIZE_DIMENSION, PAPER_SIZE)
    doc.setFont("Roboto-Bold", "bold")
    doc.autoPrint()

    console.log(data)
    for (const prop in template) {
      const propValue = data[prop]

      const {
        type = "",
        text = "",
        size = 12,
        x = 0,
        y = 0,
        width = 0,
        height = 0,
        path = "",
      } = template[prop]

      if (type === "label") {
        doc.setFontSize(size)
        doc.text(text, x, y)
      } else if (type === "text") {
        doc.setFontSize(size)
        if (prop === "cardNumber") {
          // для номера карты делает форматирование
          const formattedCardNumber = formatCardNumber(propValue)
          doc.text(formattedCardNumber, x, y)
        } else {
          // для остальных полей показываем обычный текст
          doc.text(propValue, x, y)
        }
      } else if (type === "image") {
        const imgURI = path + "/" + propValue
        const imgData = await getImage(imgURI)
        doc.addImage(imgData, x, y, width, height)
      }
    }

    const output = doc.output("bloburl")
    return Promise.resolve(output)
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

const generatePrint2 = async (data, template) => {
  try {
    const doc = new jsPDF(ORIENTATION, SIZE_DIMENSION, PAPER_SIZE)
    doc.setFont("Roboto-Bold", "bold")
    // doc.setTextColor(53, 57, 53)
    doc.autoPrint()

    for (const prop in template) {
      const propValue = data[prop]

      const {
        type = "",
        text = "",
        size = 12,
        x = 0,
        y = 0,
        width = 0,
        height = 0,
        color = "black",
      } = template[prop]

      doc.setTextColor(color)
      if (type === "label") {
        doc.setFontSize(size)
        doc.text(text, x, y)
      } else if (type === "text") {
        doc.setFontSize(size)
        if (prop === "cardNumber") {
          // для номера карты делает форматирование
          const formattedCardNumber = formatCardNumber(propValue)
          doc.text(formattedCardNumber, x, y)
        } else {
          // для остальных полей показываем обычный текст
          doc.text(propValue, x, y)
        }
      } else if (type === "image") {
        doc.addImage(propValue, "png", x, y, width, height)
      }
    }

    const output = doc.output("bloburl")
    return Promise.resolve(output)
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

export { generatePrint, generatePrint2 }
