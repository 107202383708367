const getDateFromIIN = (iin) => {
  if (iin.length !== 12) {
    return null
  }

  const dateDigits = iin.substring(0, 6)

  const year = dateDigits.substring(0, 2)
  const month = dateDigits.substring(2, 4)
  const day = dateDigits.substring(4, 6)
  const century = iin[6]

  // 0 - для иностранных граждан
  // если меньше 5, то значит это 20, 19 век

  let potentialDate = null

  if (century > 0 && century < 5) {
    potentialDate = new Date(`19${year}-${month}-${day}`)
  } else {
    potentialDate = new Date(`20${year}-${month}-${day}`)
  }

  if (isNaN(potentialDate.getTime())) {
    return null
  }

  return potentialDate
}

const isDatesEqual = (date1, date2) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  console.log(d1, d2)
  return d1.getTime() === d2.getTime()
}

const isDateAfterToday = (date) => {
  return new Date(date) > new Date()
}

const isAgeLessThanFiveYears = (date) => {
  const today = new Date()
  const birthdate = new Date(date)

  const ageInMilliseconds = today - birthdate
  const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365)

  return ageInYears <= 5
}

const isAgeLessThanSixteenYears = (date) => {
  const today = new Date()
  const birthdate = new Date(date)

  const ageInMilliseconds = today - birthdate
  const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365)

  return ageInYears <= 16
}

const getWarnings = (person) => {
  const { iin, birthdate, ascribedStatusCode } = person
  const birthdateFromIIN = getDateFromIIN(iin)
  const attentions = []
  if (!isDatesEqual(birthdateFromIIN, birthdate)) {
    attentions.push("Дата рождения не совпадает с датой в ИИН")
  }
  if (isDateAfterToday(birthdate)) {
    attentions.push("Дата рождения больше текущей даты")
  }
  if (isAgeLessThanFiveYears(birthdate)) {
    attentions.push("Возраст пользователя меньше 5 лет")
  }
  if (
    isAgeLessThanSixteenYears(birthdate) &&
    ascribedStatusCode === "STUDENT"
  ) {
    attentions.push("Возраст студента меньше 16 лет")
  }

  return attentions
}

export default { getWarnings }
