const genderCodes = [
  {
    value: "MALE",
    title: "Мужчина",
  },
  {
    value: "FEMALE",
    title: "Женщина",
  },
]

export default genderCodes
