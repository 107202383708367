<template>
  <app-page title="Привязка карты" double>
    <template #header>
      <v-text-field
        v-model="personIIN"
        class="mb-4 tw-font-medium text-grey"
        label="ИИН"
        density="compact"
        hide-details
        variant="outlined"
        readonly
      ></v-text-field>
      <!--  -->
    </template>
    <template #left>
      <v-card>
        <v-card-text>
          <alert-block
            class="tw-mb-4"
            text="Введите номер карты, которую хотите привязать"
          />
          <v-text-field
            v-model.trim="cardNumber"
            class="mb-4 tw-font-medium"
            label="Номер карты"
            density="compact"
            color="primary"
            hide-details
            variant="outlined"
          ></v-text-field>
          <dialog-confirm-button
            color="purple"
            @confirm="attachPersonToCard"
            :disabled="!cardNumber"
            block
            >Привязать карту</dialog-confirm-button
          >
        </v-card-text>
      </v-card>
      <!--  -->
    </template>
    <template #right>
      <!--  -->
    </template>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import DialogConfirmButton from "@/components/DialogConfirmButton.vue"
import AlertBlock from "@/components/AlertBlock.vue"

import { ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import { attachPerson } from "@/modules/cardwork/services/CardService"
import { useNotification } from "@kyvg/vue3-notification"

const route = useRoute()
const personIIN = ref(null)
const cardNumber = ref(null)

const { notify } = useNotification()

onMounted(() => {
  const id = route.params.id
  if (id) {
    personIIN.value = id
  }
})

const attachPersonToCard = async (finishCallback) => {
  const data = {
    cardNumber: cardNumber.value,
    iin: personIIN.value,
  }
  try {
    await attachPerson(data)
    notify({
      title: "Готово!",
      text: "Карта успешно привязана",
      type: "green",
    })
  } catch (error) {
    console.log(error)
  } finally {
    finishCallback()
  }
}
</script>
@/modules/cardwork/services/CardService
