<template>
  <app-page title="Отчет по маршрутам">
    <div class="tw-flex tw-flex-col tw-gap-4">
      <date-picker v-model="dateRange" @update="fetchData" hourly />
      <v-progress-linear
        v-if="isLoading"
        class="rounded"
        height="6"
        color="primary"
        indeterminate
      />

      <div
        class="tw-snap-x tw-flex tw-gap-4 md:tw-overflow-x-auto md:tw-overflow-y-hidden md:no-scrollbar"
      >
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          v-if="!isLoading"
          :data="chartData"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="count"
          title="Проверки"
          :colors="['#6200EE']"
        />
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          v-if="!isLoading"
          :data="chartData"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="busCount"
          title="Автобусы"
          :colors="['#F9A825']"
        />
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          v-if="!isLoading"
          :data="chartData"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="routeCount"
          title="Маршруты"
          :colors="['#2962FF']"
        />
      </div>
      <report-table
        v-if="!isLoading"
        :table-data="items"
        :fields="tableFields"
        filterable
        summaries
        @on-item-click="onItemClick"
        :exportTitle="'Отчет КРС'"
        exportSubtitle="По маршрутам"
        :exportDescription="textDateRange"
      >
      </report-table>
    </div>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import DatePicker from "@/modules/ticketwork/components/DatePicker.vue"
import ReportChart from "@/modules/ticketwork/components/ReportChart.vue"
import ReportTable from "@/modules/ticketwork/components/ReportTable.vue"

import { ref } from "vue"
import { useRouter } from "vue-router"
import {
  getReportRoutes,
  getReportRoutesByDate,
  getReportRoutesByHour,
} from "@/modules/ticketwork/services/KrsService"
import { formatDate } from "@/utils/date-utils"
import { convertToHour } from "@/utils/date-utils"
import { useDateRange } from "@/composables/useDateRange.js"

const router = useRouter()
const items = ref([])
const isLoading = ref(true)
const chartData = ref([])
const { dateRange, isRange, textDateRange } = useDateRange()

const fetchDataByDays = async () => {
  try {
    chartData.value = []
    const from = dateRange.value.from
    const to = dateRange.value.to
    const response = await getReportRoutesByDate(from, to)
    chartData.value = response.data
    chartData.value = response.data.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    )
    chartData.value = response.data.map((item) => {
      return {
        ...item,
        dateConverted: formatDate(item.date, "DD MMM"),
        succesTicketCount: item.count - item.invalidTicketCount,
      }
    })
  } catch (error) {
    console.error(error)
  }
}

const fetchDataByHours = async () => {
  try {
    chartData.value = []
    const from = dateRange.value.from
    const response = await getReportRoutesByHour(from)
    chartData.value = response.data
    chartData.value = response.data.sort((a, b) => a.hour - b.hour)
    chartData.value = response.data.map((item) => {
      return {
        ...item,
        hourConverted: convertToHour(item.hour),
        succesTicketCount: item.count - item.invalidTicketCount,
      }
    })
  } catch (error) {
    console.error(error)
  }
}

const fetchReportByRoute = async () => {
  try {
    const from = dateRange.value.from
    const to = dateRange.value.to
    const response = await getReportRoutes(from, to)
    if (response.data) {
      items.value = response.data.map((item) => {
        return {
          ...item,
          succesTicketCount: item.count - item.invalidTicketCount,
        }
      })
    }
  } catch (error) {
    console.error(error)
  }
}

const fetchData = async (isDayly) => {
  isRange.value = isDayly

  isLoading.value = true
  if (isDayly) {
    await Promise.all([fetchDataByDays(), fetchReportByRoute()])
  } else {
    await Promise.all([fetchDataByHours(), fetchReportByRoute()])
  }
  isLoading.value = false
}

const onItemClick = (item) => {
  router.push(
    `/krs/report/routes/${item.route}?from=${dateRange.value.from}&to=${dateRange.value.to}`
  )
}

const tableFields = ref({
  rows: [
    {
      columns: [
        {
          title: "Начало",
          field: "startTime",
          rowspan: 1,
          colspan: 1,
          type: "date",
          class: "tw-whitespace-nowrap tw-font-medium tw-text-center",
        },
        {
          title: "Конец",
          field: "endTime",
          rowspan: 1,
          colspan: 1,
          type: "date",
          class: "tw-whitespace-nowrap tw-font-medium tw-text-center",
        },
        {
          title: "Маршрут",
          field: "route",
          rowspan: 1,
          colspan: 1,
          type: "text",
          class: "tw-text-right",
          filterable: true,
        },
        {
          title: "Кол-во автобусов",
          field: "busCount",
          rowspan: 1,
          colspan: 1,
          type: "number",
          class: "tw-text-right ",
        },
        {
          title: "Количество проверок",
          rowspan: 1,
          colspan: 3,
          type: "number",

          columns: [
            {
              title: "Общее",
              field: "count",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right ",
            },
            {
              title: "Успешные",
              field: "succesTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-green-600",
              export: {
                textColor: "#4CAF50",
              },
            },
            {
              title: "Неуспешные",
              field: "invalidTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-red-600",
              export: {
                textColor: "#F44336",
              },
            },
          ],
        },
      ],
    },
  ],
})
</script>
