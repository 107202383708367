<template>
  <div>
    <vue-apex-charts
      v-if="!isDataEmpty"
      class="bg-white tw-w-full tw-border tw-rounded-lg tw-p-2"
      width="100%"
      height="200"
      :options="chartOptions"
      :series="chartSeries"
    ></vue-apex-charts>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue"
import VueApexCharts from "vue3-apexcharts"
import ru from "apexcharts/dist/locales/ru.json"

const props = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => [],
  },
  x: {
    type: String,
    required: true,
  },
  y: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  colors: {
    type: Array,
    default: () => ["#6200EE"],
  },
})

const chartData = computed(() => {
  console.log(props.data)
  if (!props.data) {
    return []
  }
  const data = props.data
    .map((item) => ({
      x: item[props.x],
      y: item[props.y],
    }))
    .sort((a, b) => new Date(a.x) - new Date(b.x))
  return data
})

const isDataEmpty = computed(() => chartData.value.length === 0)

const chartOptions = computed(() => ({
  colors: props.colors,
  chart: {
    fontFamily: "Roboto,  sans-serif",
    animations: {
      enabled: false,
    },
    locales: [ru],
    defaultLocale: "ru",
    type: "area",
    stacked: false,
    height: 200,
    zoom: {
      type: "x",
      enabled: false,
      autoScaleYaxis: true,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.3,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
  grid: {
    borderColor: "#F3F4F6",
    position: "back",
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  title: {
    text: props.title,
    align: "center",
    offsetY: 18,
    style: {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  xaxis: {
    type: "category",
    show: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      rotate: 0,
      format: "dd MMM",
      style: {
        fontSize: "0.8rem",
      },
      offsetY: 2,
    },
    overwriteCategories: getShortXLabels.value,
  },
  yaxis: {
    type: "category",
    tickAmount: 3,
    labels: {
      rotate: 0,
      style: {
        fontSize: "0.8rem",
      },
    },
  },
  noData: {
    text: "Нет данных",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
}))

const getShortXLabels = computed(() => {
  const xValues = chartData.value.map((item) => item.x)
  const length = xValues.length
  const middleIndex = Math.floor(length / 2)

  const firstValue = xValues[0]
  const middleValue = xValues[middleIndex]
  const lastValue = xValues[length - 1]

  if (length >= 3) {
    return [firstValue, middleValue, lastValue]
  }
  if (length === 2) {
    return [firstValue, lastValue]
  }
  if (length === 1) {
    return [firstValue]
  }
  return []
})

const chartSeries = computed(() => [
  {
    name: "Количество",
    data: chartData.value,
  },
])
</script>
