<template>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <div class="tw-flex tw-gap-2">
      <div
        v-if="filterable"
        class="tw-px-4 tw-flex tw-items-center tw-bg-white tw-border tw-rounded-lg md:tw-w-full"
      >
        <label class="tw-flex tw-items-center" for="search-input">
          <span class="material-symbols-outlined tw-text-gray-300">search</span>
        </label>
        <input
          v-model="filterInput"
          id="search-input"
          class="tw-w-full tw-text-md tw-px-4 tw-py-2 focus:tw-outline-none"
          type="text"
          placeholder="Поиск"
        />
      </div>
      <a-button
        v-if="exportable"
        class="tw-bg-white !tw-text-emerald-500"
        @click="saveTable"
      >
        <span class="material-symbols-outlined">download</span>
        <span class="md:tw-hidden tw-font-medium"> Сохранить в PDF </span>
      </a-button>
    </div>

    <div
      v-if="getSortedData.length === 0"
      class="tw-bg-white tw-border tw-rounded-lg"
    >
      <div
        class="tw-p-4 tw-flex tw-items-center tw-justify-center tw-text-gray-300"
      >
        Нет данных
      </div>
    </div>
    <div v-else class="tw-bg-white tw-border tw-rounded-lg md:tw-overflow-auto">
      <!-- <div>
      <div v-for="item in getSortedData" :key="item" class="tw-p-4 tw-border">
        <div class="tw-flex tw-flex-col">
          <span
            v-for="value in tableValues"
            :key="value.field"
            class="tw-order-none"
            :class="value.list?.class"
          >
            <slot :name="value.field" :item="item">
              <span>{{ value.title }}: </span>
              {{
                item[value.field] !== ""
                  ? formatedTableValue(item[value.field], value.type)
                  : "—"
              }}
            </slot>
          </span>
        </div>
      </div>
    </div> -->
      <table id="report-table" class="tw-w-full">
        <thead>
          <!-- TABLE fields -->
          <tr
            class="tw-border-b tw-border-gray-100"
            v-for="(row, i) in tableHeaders.rows"
            :key="i"
          >
            <th
              v-for="(column, i) in row.columns"
              :rowspan="column.rowspan"
              :colspan="column.colspan"
              class="tw-px-4 tw-py-2 tw-border-r tw-border-gray-100 last:tw-border-none tw-text-center tw-max-w-28 tw-truncate"
              :key="i"
            >
              <span class="tw-uppercase">{{ column.title }}</span>
            </th>
          </tr>
          <!-- TABLE FILTERS -->
          <tr class="tw-border-b tw-border-gray-100">
            <th
              v-for="(value, i) in tableValues"
              rowspan="2"
              class="tw-px-4 tw-py-2 tw-border-r tw-border-gray-100 last:tw-border-none hover:tw-bg-gray-50 tw-cursor-pointer tw-text-center"
              @click="
                setSortField(value.sortField ? value.sortField : value.field)
              "
              :key="i"
            >
              <span
                :class="
                  sortField === value.field || sortField === value.sortField
                    ? 'tw-text-black'
                    : 'tw-text-gray-300'
                "
              >
                {{
                  (sortField === value.field ||
                    sortField === value.sortField) &&
                  sortDirection
                    ? "&#8593;"
                    : "&#8595;"
                }}
              </span>
            </th>
          </tr>
        </thead>
        <!-- Total Header for Mobile UI -->
        <thead>
          <tr
            v-if="summaries"
            class="tw-hidden md:tw-table-row tw-bg-gray-50 tw-text-black tw-border-b tw-border-gray-100"
          >
            <td
              v-for="(value, i) in tableValues"
              :key="i"
              class="tw-px-4 tw-py-2 tw-border-r tw-border-gray-100 last:tw-border-none tw-text-right tw-font-medium"
            >
              <slot :name="value.field + 'Total'">
                {{ value.type === "number" ? getFieldTotal(value.field) : "" }}
              </slot>
            </td>
          </tr>
        </thead>
        <tbody>
          <!-- TABLE VALUES -->
          <tr
            v-for="(item, i) in getSortedData"
            class="tw-bg-white tw-border-b tw-border-gray-100 last:tw-border-none tw-cursor-pointer hover:tw-outline hover:tw-rounded hover:tw-outline-1 hover:tw-outline-gray-300"
            :key="i"
            @click="onItemClick(item)"
          >
            <td
              v-for="(value, i) in tableValues"
              class="tw-px-4 tw-py-2 tw-text-gray-900 tw-border-r tw-border-gray-100 last:tw-border-none"
              :class="value.class"
              :key="i"
            >
              <slot :name="value.field" :item="item">
                {{
                  item[value.field] !== ""
                    ? formatedTableValue(item[value.field], value.type)
                    : "—"
                }}
              </slot>
            </td>
          </tr>
          <!-- TABLE TOTALS -->
        </tbody>
        <tfoot class="tw-sticky tw-bottom-0">
          <tr
            v-if="summaries"
            class="tw-bg-gray-50 tw-text-black tw-rounded-b-lg tw-outline tw-outline-1 tw-outline-gray-200"
          >
            <td
              v-for="(value, i) in tableValues"
              :key="i"
              class="tw-px-4 tw-py-2 tw-border-r tw-border-gray-100 last:tw-border-none tw-text-right tw-font-medium"
            >
              <slot :name="value.field + 'Total'">
                {{ value.type === "number" ? getFieldTotal(value.field) : "" }}
              </slot>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue"
import { formatDate } from "@/utils/date-utils"

import jsPDF from "jspdf"
import "jspdf-autotable"

const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  fields: {
    type: Object,
    required: true,
  },
  filterable: {
    type: Boolean,
    required: false,
    default: false,
  },
  summaries: {
    type: Boolean,
    required: false,
    default: false,
  },
  initialSortField: {
    type: String,
    required: false,
  },
  exportable: {
    type: Boolean,
    required: false,
    default: true,
  },
  exportTitle: {
    type: String,
    required: false,
    default: "Отчет",
  },
  exportSubtitle: {
    type: String,
    required: false,
    default: "",
  },
  exportDescription: {
    type: String,
    required: false,
    default: "",
  },
  exportColor: {
    type: String,
    required: false,
    default: "#4CAF50",
  },
})

const emit = defineEmits(["onItemClick"])

const sortDirection = ref(false)
const filterInput = ref("")

const tableHeaders = computed(() => {
  const headers = props.fields.rows.map((row) => {
    return { columns: row.columns.filter((column) => !column.exportOnly) }
  })
  return { rows: headers }
})

const getSortedData = computed(() => {
  let data = JSON.parse(JSON.stringify(props.tableData))

  if (props.filterable && filterInput.value !== "") {
    data = data.filter((item) => {
      return tableValues.value
        .filter((value) => value.filterable)
        .find((value) => {
          if (item[value.field]) {
            return item[value.field]
              .toString()
              .toLowerCase()
              .includes(filterInput.value.toString().toLowerCase())
          }
          return false
        })
    })
  }
  data.sort((a, b) => {
    const modifier = sortDirection.value ? 1 : -1
    return a[sortField.value] > b[sortField.value] ? modifier : -modifier
  })

  return data
})

const setSortField = (field) => {
  if (sortField.value === field) {
    sortDirection.value = !sortDirection.value
  }
  sortField.value = field
}

const getFieldTotal = (field) => {
  return getSortedData.value.reduce(
    (acc, current) => (acc += current[field]),
    0
  )
}

const tableValues = computed(() => {
  const columns = []
  tableHeaders.value.rows.forEach((row) => {
    row.columns.forEach((column) => {
      if (column.field) {
        columns.push(column)
      }
      if (column.columns) {
        column.columns.forEach((nestedColumn) => {
          columns.push(nestedColumn)
        })
      }
    })
  })

  return columns
})

const sortField = ref(
  props.initialSortField ? props.initialSortField : tableValues.value[0].field
)

const formatedTableValue = (value, type) => {
  if (type === "date") {
    return formatDate(value, "DD.MM.YYYY HH:mm")
  }
  if (type === "onlyDate") {
    return formatDate(value, "DD.MM.YYYY")
  }
  // add more type formats

  return value === "" ? "—" : value
}

const onItemClick = (item) => {
  emit("onItemClick", item)
}

const saveTable = () => {
  const doc = new jsPDF()
  doc.setFont("Roboto-Medium", "normal")
  doc.setFontSize(18)
  doc.text(props.exportTitle, 14, 14)
  doc.setFontSize(14)
  doc.text(props.exportSubtitle, 14, 27)
  doc.text(props.exportDescription, 14, 34)

  const columnStyles = {}
  tableValues.value.forEach((item, i) => {
    if (item.export && item.export.width) {
      columnStyles[i] = { cellWidth: item.export.width }
    }
  })

  const dataFields = []
  props.fields.rows.forEach((row) => {
    row.columns.forEach((column) => {
      if (column.field && !column.tableOnly) {
        dataFields.push(column)
      }
      if (column.columns) {
        column.columns.forEach((nestedColumn) => {
          dataFields.push(nestedColumn)
        })
      }
    })
  })

  const dataFieldsForExport = dataFields.map((item) => ({
    header: item.title,
    dataKey: item.field,
    type: item.type,
    textColor:
      item.export && item.export.textColor ? item.export.textColor : "black",
  }))

  const data = JSON.parse(JSON.stringify(getSortedData.value))

  const headers = props.fields.rows.map((row) =>
    row.columns
      .filter((column) => !column.tableOnly)
      .map((column) => ({
        content: column.title,
        colSpan: column.colspan,
        rowSpan: column.rowspan,
      }))
  )

  if (props.summaries) {
    const totalRow = {}
    dataFields.forEach((item) => {
      if (item.type === "number") {
        totalRow[item.field] = getFieldTotal(item.field)
      }
    })
    data.push(totalRow)
  }

  doc.autoTable({
    columns: dataFieldsForExport,
    head: headers,
    body: data,
    theme: "grid",
    styles: {
      cellPadding: 2.2,
      fontSize: 10,
      font: "Roboto-Medium",
      lineWidth: 0.2,
      lineColor: "#e0e0e0",
      textColor: "#000000",
    },
    headStyles: {
      font: "Roboto-Bold",
      cellPadding: 3,
      fillColor: props.exportColor,
      lineColor: "#43A047",
      halign: "center",
      valign: "middle",
      textColor: "#ffffff",
    },
    columnStyles: columnStyles,
    footStyles: {
      fillColor: "#fafafa",
      textColor: "#000000",
    },
    showFoot: "lastPage",
    startY: 40,
    didParseCell: ({ cell, column }) => {
      const section = cell.section
      if (section === "head") {
        cell.text = cell.text.join(" ").toUpperCase()
        cell.styles.halign = "center"
        return
      }
      cell.styles.textColor = column.raw.textColor

      if (isNaN(cell.raw)) {
        cell.styles.halign = "left"
      } else {
        cell.styles.halign = "right"
      }
      if (cell.raw === "") {
        cell.text = "—"
        cell.styles.halign = "center"
        cell.styles.valign = "middle"
      }
      if (column.raw.type === "date" && cell.raw && cell.raw !== "") {
        cell.text = formatDate(cell.raw, "DD.MM.YYYY HH:mm:ss")
      } else if (column.raw.type === "onlyDate" && cell.raw && cell.raw != "") {
        cell.text = formatDate(cell.raw, "DD.MM.YYYY")
      }
    },
  })
  const output = doc.output("bloburl")
  window.open(output, "_blank")
}
</script>
