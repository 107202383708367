<template>
  <app-page title="Мгновенный выпуск карты" double>
    <template #header> </template>
    <template #left>
      <v-card>
        <v-card-text>
          <cropper
            v-if="inputPhoto"
            class="mb-4 cropper"
            ref="imageCropper"
            :src="inputPhotoWithoutBackground || inputPhoto"
            :stencil-props="stencilProps"
            :default-size="defaultSize"
            @ready="updateCroppedImage"
            @change="updateCroppedImage"
          />
          <alert-block
            class="tw-mb-4"
            text="Проверьте данные и загрузите фотографию"
          />

          <v-file-input
            class="mb-4"
            accept="image/*"
            variant="outlined"
            label="Прикрепить фотографию"
            density="compact"
            hide-details
            clearable
            prepend-icon=""
            prepend-inner-icon="mdi-attachment"
            show-size
            @change="onFileSelected"
            :disabled="isLoading.removeBackground"
            :loading="isLoading.removeBackground"
          ></v-file-input>
          <v-text-field
            v-model="modelFields.firstName"
            class="mb-4 tw-font-medium"
            density="compact"
            hide-details="auto"
            variant="outlined"
            label="Имя"
            :loading="isLoading.fetchUser"
            :disabled="isLoading.fetchUser"
          >
          </v-text-field>
          <v-text-field
            v-model="modelFields.lastName"
            class="mb-4 tw-font-medium"
            density="compact"
            hide-details="auto"
            variant="outlined"
            label="Фамилия"
            :loading="isLoading.fetchUser"
            :disabled="isLoading.fetchUser"
          >
          </v-text-field>
          <v-text-field
            v-model="modelFields.expirationDate"
            type="date"
            class="tw-font-medium"
            density="compact"
            hide-details="auto"
            variant="outlined"
            label="Дата истечения"
            :loading="isLoading.fetchUser"
            :disabled="isLoading.fetchUser"
          >
          </v-text-field>
        </v-card-text>
      </v-card>
      <!--  -->
    </template>
    <template #right>
      <v-card>
        <v-card-text>
          <a-button
            class="mb-4"
            block
            @click="removePhotoBackground"
            :disabled="
              isLoading.removeBackground ||
              isLoading.createInstantCard ||
              isPhotoNotApplied
            "
            :loading="isLoading.removeBackground"
            >Удалить фон</a-button
          >

          <a-button
            class="mb-4"
            block
            variant="tonal"
            @click="resetPhotoBackground"
            :disabled="
              isLoading.removeBackground ||
              isLoading.createInstantCard ||
              isPhotoNotApplied
            "
            >Вернуть оригинал</a-button
          >
          <dialog-confirm-button
            block
            color="purple"
            @confirm="printCard"
            :loading="isLoading.createInstantCard"
            :disabled="
              isLoading.removeBackground ||
              isLoading.createInstantCard ||
              isPhotoNotApplied
            "
            >Отправить на печать
          </dialog-confirm-button>
        </v-card-text>
      </v-card>
      <!--  -->
    </template>
  </app-page>
</template>
<script setup>
import AppPage from "@/components/AppPage.vue"
import AlertBlock from "@/components/AlertBlock.vue"
import DialogConfirmButton from "@/components/DialogConfirmButton.vue"
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"

import { ref, toRaw, onMounted } from "vue"
import { useRoute } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification"
import { removeBackground } from "@/modules/cardwork/services/CardService"
import { reduceBlob } from "@/utils/blob-maker"
import { generatePrint2 } from "@/utils/print-helper"
import krsPrintTemplate from "@/modules/ticketwork/utils/krs-print-card-template"
import { shymkentLogo } from "@/assets/shymkentLogo"
import { stampLogo } from "@/assets/stampLogo"
import { tolemLogo } from "@/assets/tolemLogo"

import * as KrsService from "@/modules/ticketwork/services/KrsService"
import { formatDate } from "@/utils/date-utils"

const route = useRoute()
const inputPhoto = ref(null)
const inputPhotoWithoutBackground = ref(null)
const imageCropper = ref(null)
const isPhotoNotApplied = ref(true)

const isLoading = ref({
  fetchUser: false,
  createInstantCard: false,
  removeBackground: false,
  printing: false,
})

const { notify } = useNotification()

const defaultSize = ({ imageSize, visibleArea }) => {
  return {
    width: (visibleArea || imageSize).width,
    height: (visibleArea || imageSize).height,
  }
}

const stencilProps = {
  aspectRatio: 3 / 4,
}

const onFileSelected = (event) => {
  inputPhoto.value = URL.createObjectURL(event.target.files[0])
  resetPhotoBackground()
}

const updateCroppedImage = () => {
  const { canvas } = imageCropper.value.getResult()
  const base64 = canvas.toDataURL("image/png")
  modelFields.value.localPhotoUri = base64
  isPhotoNotApplied.value = false
}

const removePhotoBackground = async () => {
  isLoading.value.removeBackground = true
  try {
    const { canvas } = imageCropper.value.getResult()
    const blob = await reduceBlob(canvas, "image/jpeg")
    const formData = new FormData()
    formData.append("file", blob)
    const response = await removeBackground(formData)
    inputPhotoWithoutBackground.value = URL.createObjectURL(response.data)
    modelFields.value.localPhotoUri = inputPhotoWithoutBackground.value
  } catch (error) {
    notify({
      title: "Ошибка!",
      text: `Повторите попытку. ${error.code}, ${error.response.status}`,
      type: "red",
    })
    console.log(error)
  } finally {
    isLoading.value.removeBackground = false
  }
}

const resetPhotoBackground = () => {
  inputPhotoWithoutBackground.value = null
}

const modelFields = ref({
  firstName: "",
  lastName: "",
  expirationDate: "",
  localPhotoUri: "",
  cityLogo: shymkentLogo,
  stampLogo: stampLogo,
  tolemLogo: tolemLogo,
})

const printCard = async () => {
  try {
    isLoading.value.printing = true
    const outputKrsCard = await generatePrint2(
      toRaw(modelFields.value),
      toRaw(krsPrintTemplate)
    )
    window.open(outputKrsCard, "_blank")
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value.printing = false
  }
}

const fetchData = async () => {
  try {
    isLoading.value.fetchUser = true
    const id = route.params.id
    if (id) {
      const response = await KrsService.getUser(id)
      const user = response.data
      const firstName = user.name.split(" ")[0]
      const lastName = user.name.split(" ")[1]
      modelFields.value.firstName = firstName
      modelFields.value.lastName = lastName
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value.fetchUser = false
  }
}

onMounted(async () => {
  await fetchData()
  const currentDate = new Date()
  currentDate.setFullYear(currentDate.getFullYear() + 1)
  modelFields.value.expirationDate = formatDate(currentDate, "YYYY-MM-DD")
})
</script>

<style scoped>
.cropper {
  height: 400px;
  width: 400px;
}
</style>
@/modules/cardwork/services/CardService
