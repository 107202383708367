import { createRouter, createWebHistory } from "vue-router"

import CardworkModule from "@/modules/cardwork"
import TicketworkModule from "@/modules/ticketwork"

import { useUserStore } from "@/stores/user"
import { usePageStore } from "@/stores/page"
import * as Roles from "@/utils/role-validator"

const routes = []
const router = createRouter({
  history: createWebHistory(),
  routes,
})

CardworkModule.routes.forEach((route) => router.addRoute(route))
TicketworkModule.routes.forEach((route) => router.addRoute(route))

router.beforeEach((to, from, next) => {
  const { isAuthorized, logout } = useUserStore()
  const { changeTitle } = usePageStore()

  changeTitle(to.meta.title)

  if (to.fullPath === "/auth" && isAuthorized) {
    logout()
    return
  }

  if (to.fullPath === "/auth") {
    next()
    return
  }

  if (!isAuthorized) {
    logout()
    return
  }

  const isAllowedForCurrentRole = Roles.checkRoleIncluding(to.meta.roles)
  if (isAllowedForCurrentRole) {
    // если роль есть в мета роутах, то пускаем дальеш
    next()
    return
  } else {
    // если роли нет в мета роутах, то отправляем в базовый url для роли
    next(Roles.getBasePath())
    return
  }
})

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: "smooth" })
})

export default router
