const ascribedCodes = [
  {
    value: "SCHOOLKID",
    title: "Школьник",
  },
  {
    value: "STUDENT",
    title: "Студент",
  },
  {
    value: "BENEFIES",
    title: "Льготник",
  },
]

export default ascribedCodes
