export default {
  labelCardID: {
    type: "label",
    text: "ID №",
    x: 58,
    y: 9,
    size: 8,
  },
  cardID: {
    type: "text",
    label: "ID карты",
    x: 64,
    y: 9,
    size: 8,
  },
  lastName: {
    type: "text",
    label: "Фамилия",
    x: 5,
    y: 9,
    size: 8,
  },
  firstName: {
    type: "text",
    label: "Имя",
    x: 5,
    y: 13,
    size: 8,
  },
  labelStudyTime: {
    type: "label",
    text: "ОТ:",
    x: 5,
    y: 22,
    size: 8,
  },
  studyTime: {
    type: "text",
    label: "Время обучения",
    x: 10,
    y: 22,
    size: 8,
  },
  labelIssueDate: {
    type: "label",
    text: "БК:",
    x: 36,
    y: 22,
    size: 8,
  },
  issueDate: {
    type: "text",
    label: "Дата выдачи",
    x: 41,
    y: 22,
    size: 8,
  },
  labelFacultyName: {
    type: "label",
    text: "ФАКУЛЬТЕТ:",
    x: 5,
    y: 38,
    size: 8,
  },
  facultyName: {
    type: "text",
    label: "Факультет",
    x: 5,
    y: 42,
    size: 8,
  },
  cardNumber: {
    type: "text",
    label: "Номер карты",
    x: 58,
    y: 46,
    size: 8,
  },
  labelExpirationDate: {
    type: "label",
    text: "Жарамдылығы:",
    x: 70,
    y: 50,
    size: 4,
  },
  expirationDate: {
    type: "text",
    label: "Дата истечения",
    x: 70,
    y: 52,
    size: 4,
  },
  localPhotoUri: {
    type: "image",
    label: "Фото",
    path: "photo",
    x: 58.3,
    y: 11.6,
    width: 22.5,
    height: 30,
  },
  qrCode: {
    type: "image",
    label: "QR код",
    path: "qr",
    x: 37.3,
    y: 34.3,
    width: 17,
    height: 17,
  },
}
