import { ref } from "vue"
import { toValue } from "vue"

export const useFetchData = () => {
  const data = ref([])
  const errorData = ref(null)
  const isLoading = ref(true)

  const fetchData = async (apiCall) => {
    try {
      isLoading.value = true
      const response = await toValue(apiCall)
      data.value = response
    } catch (error) {
      errorData.value = error
    } finally {
      isLoading.value = false
    }
  }

  return { data, fetchData, isLoading, errorData }
}
