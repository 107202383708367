import { useUserStore } from "@/stores/user"

const CARDMASTER = "cardmaster"
const CARDISSUER = "cardissuer"
const CARDOPERATOR = "operator"
const TICKETMASTER = "ticketmaster"
const TICKETAUDIT = "ticketaudit"

const permissions = {
  cardWork: [CARDMASTER, CARDISSUER, CARDOPERATOR],
  createPerson: [CARDMASTER, CARDISSUER],
  editPerson: [CARDMASTER, CARDISSUER],
  blockCard: [CARDMASTER],
  activateCard: [CARDMASTER],
  handoverCard: [CARDOPERATOR, CARDISSUER],
  editStage: [CARDISSUER],
  showTransactions: [CARDMASTER],
  unassignCard: [CARDMASTER],
  attachPerson: [CARDMASTER],
  instantCard: [CARDISSUER],

  ticketWork: [TICKETMASTER, TICKETAUDIT],
  viewKrsEmployers: [TICKETMASTER],
  viewKrsReports: [TICKETMASTER, TICKETAUDIT],
}

const checkRole = (role) => {
  const store = useUserStore()
  return store.user.roles.includes(role)
}

const checkRoleIncluding = (roles) => {
  const store = useUserStore()
  if (!roles || !store.user || !store.user.roles) {
    return false
  }
  return roles.some((role) => store.user.roles.includes(role))
}

const getBasePath = () => {
  const store = useUserStore()
  if (!store.user || !store.user.roles) {
    store.logout()
    return "/auth"
  }
  if (permissions.cardWork.some((role) => store.user.roles.includes(role))) {
    return "/persons"
  }
  if (permissions.ticketWork.some((role) => store.user.roles.includes(role))) {
    return "/krs/report/users"
  }

  return "/auth"
}

const validateRole = (actionName) => {
  const store = useUserStore()
  const permission = permissions[actionName]

  if (!permission || !store.user || !store.user.roles) {
    return false
  }
  return permission.some((role) => store.user.roles.includes(role))
}

export {
  CARDMASTER,
  CARDISSUER,
  CARDOPERATOR,
  TICKETAUDIT,
  TICKETMASTER,
  checkRole,
  checkRoleIncluding,
  validateRole,
  getBasePath,
}
