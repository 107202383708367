<template>
  <v-text-field
    v-model="personData.iin"
    class="mb-4 tw-font-medium text-grey"
    label="ИИН"
    density="compact"
    hide-details
    variant="outlined"
    readonly
  ></v-text-field>
  <v-text-field
    v-model="personData.firstName"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Имя"
    density="compact"
    hide-details
    variant="outlined"
    :readonly="!isEdit"
  ></v-text-field>
  <v-text-field
    v-model="personData.lastName"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Фамилия"
    density="compact"
    hide-details
    variant="outlined"
    :readonly="!isEdit"
  ></v-text-field>
  <v-text-field
    v-model="personData.birthdate"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Дата рождения"
    density="compact"
    hide-details
    variant="outlined"
    type="date"
    :readonly="!isEdit"
  ></v-text-field>
  <v-select
    v-model="personData.genderCode"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Пол"
    density="compact"
    hide-details
    variant="outlined"
    :readonly="!isEdit"
    :items="genderCodes"
    item-title="title"
    item-value="value"
  ></v-select>
  <v-select
    v-model="personData.ascribedStatusCode"
    class="mb-4 tw-font-medium"
    :class="isEdit ? 'text-black' : 'text-grey'"
    label="Статус"
    density="compact"
    hide-details
    variant="outlined"
    :readonly="!isEdit"
    :items="ascribedCodes"
    item-title="title"
    item-value="value"
  ></v-select>
  <a-button
    v-if="!isEdit"
    block
    :disabled="!validateRole('editPerson')"
    @click="isEdit = true"
    ><span class="material-symbols-outlined">edit</span> Редактировать</a-button
  >
  <div v-else>
    <a-button
      class="mb-4"
      color="purple"
      block
      @click="updatePersonData()"
      :loading="isLoading"
      :disabled="isLoading"
      >Сохранить</a-button
    >
    <a-button block @click="isEdit = false">Отмена</a-button>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from "vue"
import { validateRole } from "@/utils/role-validator"
import ascribedCodes from "@/modules/cardwork/utils/ascribed-status-list"
import genderCodes from "@/modules/cardwork/utils/gender-code-list"
import { useNotification } from "@kyvg/vue3-notification"
import { updatePerson } from "@/modules/cardwork/services/PersonService"

const props = defineProps({
  person: {
    type: Object,
    required: true,
  },
})

const isEdit = ref(false)
const isLoading = ref(false)
const { notify } = useNotification()

const personData = computed(() => props.person)

const updatePersonData = async () => {
  isLoading.value = true
  try {
    await updatePerson(props.person)
    notify({
      title: "Готово!",
      text: "Данные успешно обновлены",
      type: "green",
    })
  } finally {
    isEdit.value = false
    isLoading.value = false
  }
}
</script>
@/modules/cardwork/services/PersonService
@/modules/cardwork/utils/ascribed-status-list
