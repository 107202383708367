import KrsPrintPage from "@/modules/ticketwork/pages/KrsPrintPage.vue"
import KrsUsersPage from "@/modules/ticketwork/pages/KrsUsersPage.vue"
import KrsUserPage from "@/modules/ticketwork/pages/KrsUserPage.vue"

import ReportUsersPage from "@/modules/ticketwork/pages/report/user/ReportUsersPage.vue"
import ReportUsersForUserPage from "@/modules/ticketwork/pages/report/user/ReportUsersForUserPage.vue"
import ReportUsersLogPage from "@/modules/ticketwork/pages/report/user/ReportUsersLogPage.vue"

import ReportBusesPage from "@/modules/ticketwork/pages/report/bus/ReportBusesPage.vue"
import ReportBusesForBusPage from "@/modules/ticketwork/pages/report/bus/ReportBusesForBusPage.vue"
import ReportBusesLogPage from "@/modules/ticketwork/pages/report/bus/ReportBusesLogPage.vue"

import ReportRoutesPage from "@/modules/ticketwork/pages/report/route/ReportRoutesPage.vue"
import ReportRoutesForRoutePage from "@/modules/ticketwork/pages/report/route/ReportRoutesForRoutePage.vue"
import ReportRoutesLogPage from "@/modules/ticketwork/pages/report/route/ReportRoutesLogPage.vue"

import * as Roles from "@/utils/role-validator"

const routes = [
  // users
  {
    path: "/krs/report/users",
    component: ReportUsersPage,
    meta: {
      title: "Отчет по сотрудникам",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/report/users/:id",
    component: ReportUsersForUserPage,
    meta: {
      title: "Отчет по сотрудникам",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/report/users/log/:id",
    component: ReportUsersLogPage,
    meta: {
      title: "Детальный лог",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },

  // buses
  {
    path: "/krs/report/buses",
    component: ReportBusesPage,
    meta: {
      title: "Отчет по автобусам",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/report/buses/:id",
    component: ReportBusesForBusPage,
    meta: {
      title: "Отчет по автобусам",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/report/buses/log/:id",
    component: ReportBusesLogPage,
    meta: {
      title: "Детальный лог",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },

  // routes
  {
    path: "/krs/report/routes",
    component: ReportRoutesPage,
    meta: {
      title: "Отчет по маршрутам",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/report/routes/:id",
    component: ReportRoutesForRoutePage,
    meta: {
      title: "Отчет по маршрутам",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/report/routes/log/:id",
    component: ReportRoutesLogPage,
    meta: {
      title: "Детальный лог",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },

  {
    path: "/krs/print",
    component: KrsPrintPage,
    meta: {
      title: "Печать",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/print/:id",
    component: KrsPrintPage,
    meta: {
      title: "Печать",
      roles: [Roles.TICKETMASTER, Roles.TICKETAUDIT],
    },
  },
  {
    path: "/krs/users",
    component: KrsUsersPage,
    meta: {
      title: "Сотрудники",
      roles: [Roles.TICKETMASTER],
    },
  },
  {
    path: "/krs/users/:id",
    component: KrsUserPage,
    meta: {
      title: "Сотрудники",
      roles: [Roles.TICKETMASTER],
    },
  },
  {
    path: "/krs/users/create",
    component: KrsUserPage,
    meta: {
      title: "Новый сотрудник",
      roles: [Roles.TICKETMASTER],
    },
  },
]

export default routes
