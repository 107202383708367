export default {
  cardID: {
    type: "text",
    label: "ID карты",
    x: 64,
    y: 9,
    size: 9,
  },
  firstName: {
    type: "text",
    label: "Имя",
    x: 8.5,
    y: 22,
    size: 9,
  },
  lastName: {
    type: "text",
    label: "Фамилия",
    x: 8.5,
    y: 17,
    size: 9,
  },
  iin: {
    type: "text",
    label: "ИИН",
    x: 8.5,
    y: 26,
    size: 6,
  },
  studyTime: {
    type: "text",
    label: "Время обучения",
    x: 29.5,
    y: 26,
    size: 6,
  },
  issueDate: {
    type: "text",
    label: "Дата выдачи",
    x: 43.5,
    y: 26,
    size: 6,
  },
  facultyName: {
    type: "text",
    label: "Факультет",
    x: 3,
    y: 30.5,
    size: 6,
  },
  facultyCode: {
    type: "text",
    label: "BBB шифр",
    x: 15,
    y: 36,
    size: 6,
  },
  cardNumber: {
    type: "text",
    label: "Номер карты",
    x: 58,
    y: 43.5,
    size: 6,
  },
  expirationDate: {
    type: "text",
    label: "Дата истечения",
    x: 70,
    y: 49.8,
    size: 6,
  },
  localPhotoUri: {
    type: "image",
    label: "Фото",
    path: "photo",
    x: 58.8,
    y: 13.8,
    width: 17,
    height: 22.6,
  },
  qrCode: {
    type: "image",
    label: "QR код",
    path: "qr",
    x: 36.2,
    y: 32.7,
    width: 18,
    height: 18,
  },
}
