<template>
  <p class="mb-4 text-body-1 text-grey">Информация по школе</p>
  <v-text-field
    v-model="bgpSchool.name"
    class="mb-4 tw-font-medium text-grey"
    label="Название"
    density="compact"
    hide-details="auto"
    variant="outlined"
    readonly
  ></v-text-field>
  <v-text-field
    v-model="bgpSchool.bin"
    class="mb-4 tw-font-medium text-grey"
    label="БИН"
    density="compact"
    hide-details="auto"
    variant="outlined"
  ></v-text-field>
  <v-text-field
    v-model="bgpSchool.cateringBin"
    class="mb-4 tw-font-medium text-grey"
    label="Столовая"
    density="compact"
    hide-details="auto"
    variant="outlined"
  ></v-text-field>
  <v-text-field
    v-model="bgpSchool.grade"
    class="tw-font-medium text-grey"
    label="Класс"
    density="compact"
    hide-details="auto"
    variant="outlined"
  ></v-text-field>
</template>

<script setup>
import { ref, defineProps } from "vue"

const props = defineProps({
  card: {
    type: Object,
    required: true,
  },
})

const bgpSchool = ref(props.card.bgpSchool)
</script>
