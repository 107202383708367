import axios from "@/plugins/axios.js"
import { formatDate } from "@/utils/date-utils"
import { convertToHour } from "@/utils/date-utils"

const getUsers = async (range) => {
  console.log(range)
  try {
    const response = await axios.get("ticket-audit-users", {
      data: {},
    })
    if (!(response && response.data)) {
      throw new Error()
    }

    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getUser = async (id) => {
  try {
    const response = await axios.get(`ticket-audit-users?user=${id}`, {
      data: {},
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateUser = async (data) => {
  try {
    const response = await axios.put("ticket-audit-users", data)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const createUser = async (data) => {
  try {
    const response = await axios.post("ticket-audit-users", data)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`ticket-audit-users?iin=${id}`, {
      data: {},
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportUsers = async ({ from, to }) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/user?from=${from}&to=${to}`,
      { data: {} }
    )

    if (!(response && response.data)) {
      throw new Error()
    }

    const data = response.data.map((item) => ({
      ...item,
      succesTicketCount: item.count - item.invalidTicketCount,
    }))
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportUsersByDate = async ({ from, to }) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/date?from=${from}&to=${to}`,
      { data: {} }
    )
    if (response && response.data) {
      const data = response.data
        .map((item) => {
          return {
            ...item,
            dateConverted: formatDate(item.date, "DD MMM"),
            succesTicketCount: item.count - item.invalidTicketCount,
          }
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date))
      return Promise.resolve(data)
    }
    throw new Error()
  } catch (error) {
    return Promise.reject(error)
  }
}
const getReportUsersByHour = async ({ from }) => {
  console.log(from)
  try {
    const response = await axios.get(
      `ticket-audit/report/by-hour?date=${from}`,
      { data: {} }
    )
    if (response && response.data) {
      const data = response.data
        .map((item) => {
          return {
            ...item,
            hourConverted: convertToHour(item.hour),
            succesTicketCount: item.count - item.invalidTicketCount,
          }
        })
        .sort((a, b) => a.hour - b.hour)
      return Promise.resolve(data)
    }
    throw new Error()
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportUsersByDateForUser = async (from, to, id) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/date?from=${from}&to=${to}&iin=${id}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportUsersByHourForUser = async (date, id = "") => {
  try {
    const response = await axios.get(
      `ticket-audit/report/by-hour?user=${id}&date=${date}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportUserLog = async (id, from, to) => {
  try {
    const response = await axios.get(
      `ticket-audit/log/${id}?from=${from}&to=${to}`,
      { data: {} }
    )
    if (response && response.data) {
      const data = response.data.map((item) => {
        return {
          ...item,
          ticketResult: formatTicketInfo(item),
          typeParsed: formatEventTypeMod(item.type).title,
          requestParsed: formatRequestTypeMod(item.request.searchType).title,
        }
      })
      return Promise.resolve(data)
    }
    throw new Error()
  } catch (error) {
    return Promise.reject(error)
  }
}

// BUS

const getReportBuses = async (from, to) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/bus?from=${from}&to=${to}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportBusesByDate = async (from, to) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/date?from=${from}&to=${to}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportBusesByDateForBus = async (from, to, id) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/date?from=${from}&to=${to}&registrationNumber=${id}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportBusesByHour = async (date) => {
  try {
    const response = await axios.get(
      `ticket-audit/bus-report/by-hour?date=${date}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportBusesByHourForBus = async (date, id) => {
  try {
    const response = await axios.get(
      `ticket-audit/bus-report/by-hour?date=${date}&registrationNumber=${id}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportBusLog = async (id, from, to) => {
  try {
    const response = await axios.get(
      `ticket-audit/bus-log/${id}?from=${from}&to=${to}`,
      { data: {} }
    )
    if (response && response.data) {
      const data = response.data.map((item) => {
        return {
          ...item,
          ticketResult: formatTicketInfo(item),
          typeParsed: formatEventTypeMod(item.type).title,
          requestParsed: formatRequestTypeMod(item.request.searchType).title,
        }
      })
      return Promise.resolve(data)
    }
    throw new Error()
  } catch (error) {
    return Promise.reject(error)
  }
}

// ROUTE
const getReportRoutes = async (from, to) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/route?from=${from}&to=${to}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportRoutesByDate = async (from, to) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/date?from=${from}&to=${to}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportRoutesByDateForRoute = async (from, to, id) => {
  try {
    const response = await axios.get(
      `ticket-audit/report/group-by/date?from=${from}&to=${to}&route=${id}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportRoutesByHour = async (date) => {
  try {
    const response = await axios.get(
      `ticket-audit/route-report/by-hour?date=${date}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportRoutesByHourForRoute = async (date, id) => {
  try {
    const response = await axios.get(
      `ticket-audit/route-report/by-hour?date=${date}&route=${id}`,
      { data: {} }
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getReportRouteLog = async (id, from, to) => {
  try {
    const response = await axios.get(
      `ticket-audit/route-log/${id}?from=${from}&to=${to}`,
      { data: {} }
    )
    if (response && response.data) {
      const data = response.data.map((item) => {
        return {
          ...item,
          ticketResult: formatTicketInfo(item),
          typeParsed: formatEventTypeMod(item.type).title,
          requestParsed: formatRequestTypeMod(item.request.searchType).title,
        }
      })
      return Promise.resolve(data)
    }
    throw new Error()
  } catch (error) {
    return Promise.reject(error)
  }
}

const formatEventType = (type) => {
  switch (type) {
    case "ticket":
      return "Проверка билета"
    case "login":
      return "Вход в приложение"
    case "resume":
      return "Разблокировка"
    case "block":
      return "Блокировка"
    default:
      return "Неизвестный тип"
  }
}
// tw-text-red-700 tw-bg-red-100 tw-border-red-300
// tw-text-green-700 tw-bg-green-100 tw-border-green-300
// tw-text-emerald-700 tw-bg-emerald-50
// tw-text-indigo-700 tw-bg-indigo-50
// tw-text-gray-700 tw-bg-gray-50

const formatEventTypeMod = (type) => {
  switch (type) {
    case "ticket":
      return {
        title: "Проверка билета",
        icon: "confirmation_number",
        color: "blue",
      }
    case "login":
      return {
        title: "Вход в приложение",
        icon: "login",
        color: "yellow",
      }
    case "resume":
      return {
        title: "Разблокировка",
        icon: "lock_open",
        color: "emerald",
      }
    case "block":
      return {
        title: "Блокировка",
        icon: "lock",
        color: "red",
      }
    default:
      return {
        title: "Неизвестный тип",
        icon: "",
        color: "gray",
      }
  }
}

const formatRequestType = (type) => {
  switch (type) {
    case "card-id":
      return "ID карты"
    case "phone":
      return "По номеру телефона"
    case "ticket-id":
      return "По номеру билета"
    case "card":
      return "По номеру карты"
    case "":
      return ""
    default:
      return "Неизвестный тип"
  }
}
const formatRequestTypeMod = (type) => {
  switch (type) {
    case "card-id":
      return {
        title: "ID карты",
        icon: "id_card",
        color: "purple",
      }
    case "phone":
      return {
        title: "Номер телефона",
        icon: "smartphone",
        color: "gray",
      }
    case "ticket-id":
      return {
        title: "Номер билета",
        icon: "confirmation_number",
        color: "gray",
      }
    case "card":
      return {
        title: "Номер карты",
        icon: "pin",
        color: "gray",
      }
    case "":
      return {
        title: "",
        icon: "",
        color: "",
      }
    default:
      return {
        title: "Неизвестный тип",
        icon: "",
        color: "gray",
      }
  }
}

const formatError = (error) => {
  switch (error) {
    case "ticket expired":
      return "Просроченный билет"
    case "ticket not found":
      return "Билет не найден"
    case "ticket missmatch":
      return "Оплата в другом автобусе"
    case "service error":
      return "Ошибка системы"
    case "":
      return ""
    default:
      return "Неизвестно"
  }
}

const formatTicketInfo = (item) => {
  const ticketResultMessage =
    formatDate(item.ticket.dateUtc, "DD.MM.YYYY HH:mm") +
    "\n" +
    formatError(item.error) +
    (formatError(item.error) ? "\n" : "") +
    "\n" +
    "ID: " +
    item.ticket.id +
    "\n" +
    "Маршрут: " +
    item.ticket.route +
    "\n" +
    "Сумма: " +
    item.ticket.amount +
    "\n" +
    "ГРНЗ: " +
    item.ticket.busRegistrationNumber

  const ticketNotFoundMessage = formatError(item.error)

  if (
    item.type === "ticket" &&
    (item.error === "" || item.error === "ticket missmatch")
  ) {
    return ticketResultMessage
  }
  return ticketNotFoundMessage
}

export { getUsers, getUser, updateUser, createUser, deleteUser }
export {
  getReportUsers,
  getReportUsersByDate,
  getReportUsersByDateForUser,
  getReportUsersByHour,
  getReportUsersByHourForUser,
  getReportUserLog,
}
export {
  getReportBuses,
  getReportBusesByDate,
  getReportBusesByDateForBus,
  getReportBusesByHour,
  getReportBusesByHourForBus,
  getReportBusLog,
}
export {
  getReportRoutes,
  getReportRoutesByDate,
  getReportRoutesByDateForRoute,
  getReportRoutesByHour,
  getReportRoutesByHourForRoute,
  getReportRouteLog,
}
export {
  formatEventType,
  formatEventTypeMod,
  formatRequestType,
  formatRequestTypeMod,
  formatError,
}
