<template>
  <v-card>
    <v-card-text>
      <p class="text-body-1 text-grey mb-2">Начало</p>
      <v-form @submit.prevent="emit('fast')">
        <v-text-field
          v-model="value.from"
          class="mb-2 tw-font-medium"
          density="compact"
          hide-details
          variant="outlined"
          type="date"
        ></v-text-field>
        <p v-if="!single" class="text-body-1 text-grey mb-2">Конец</p>
        <v-text-field
          v-if="!single"
          v-model="value.to"
          class="mb-4 tw-font-medium"
          density="compact"
          hide-details
          variant="outlined"
          type="date"
        ></v-text-field>
        <v-chip class="mb-2 mr-2 tw-font-medium" label @click="fastDate(7)">
          7 дней
        </v-chip>
        <v-chip class="mb-2 mr-2 tw-font-medium" label @click="fastDate(14)">
          14 дней
        </v-chip>
        <v-chip class="mb-2 mr-2 tw-font-medium" label @click="fastDate(30)">
          30 дней
        </v-chip>
        <v-chip class="mb-2 mr-2 tw-font-medium" label @click="fastDate(60)">
          60 дней
        </v-chip>
        <v-btn block variant="tonal" type="submit">
          <strong>Обновить</strong>
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue"
import { formatDate } from "@/utils/date-utils"

const props = defineProps(["modelValue", "single"])
const emit = defineEmits(["update:modelValue", "fast"])

const DATE_FORMAT = "YYYY-MM-DD"

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit("update:modelValue", value)
  },
})

value.value = {
  from: formatDate(new Date(), DATE_FORMAT, { substract: 1 }),
  to: formatDate(new Date(), DATE_FORMAT),
}

const fastDate = (days) => {
  value.value.from = formatDate(new Date(), DATE_FORMAT, {
    substract: days,
  })
  value.value.to = formatDate(new Date(), DATE_FORMAT)
  emit("fast")
}
</script>
