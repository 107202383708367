import axios from "@/plugins/axios.js"
import ScanParser from "@/modules/cardwork/utils/scan-parser"

const getPerson = async (iin) => {
  const query = ScanParser.scanPersonId(iin)
  try {
    const response = await axios.get(`person/${query}`, {
      data: {},
    })
    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updatePerson = async (person) => {
  try {
    const response = await axios.put(`person/${person.iin}`, person)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const createPerson = async (person) => {
  try {
    const response = await axios.post(`person`, person)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export { getPerson, updatePerson, createPerson }
