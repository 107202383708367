<template>
  <app-page title="Моментальные карты" double>
    <template #header>
      <!--  -->
      <div class="tw-flex tw-flex-col tw-gap-4" v-if="isFetchingLoading">
        <alert-block
          text="Здесь будут отображаться карты с мгновенного выпуска"
        />
        <v-progress-linear
          class="rounded"
          height="6"
          color="primary"
          indeterminate
        />
      </div>
    </template>
    <template #left>
      <!--  -->
      <instant-card-item
        v-for="(card, i) in instantCards"
        :card="card"
        :key="i"
        @click="onItemClick(card)"
      ></instant-card-item>
    </template>
    <template #right>
      <instant-data-block v-if="activeCard" :card="activeCard" />
    </template>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import AlertBlock from "@/components/AlertBlock.vue"
import InstantCardItem from "@/modules/cardwork/components/InstantCardItem.vue"
import InstantDataBlock from "@/modules/cardwork/components/InstantDataBlock.vue"

import { ref, onMounted } from "vue"
import { getInstantCards } from "@/modules/cardwork/services/CardService"
import { useRouter } from "vue-router"

const router = useRouter()
const isFetchingLoading = ref(false)
const instantCards = ref([])
const activeCard = ref(null)

const fetchInstantCards = async () => {
  try {
    isFetchingLoading.value = true
    const response = await getInstantCards()
    instantCards.value = response.data
  } catch (error) {
    console.log(error)
  } finally {
    isFetchingLoading.value = false
  }
}

const onItemClick = (card) => {
  if (card.clicked) {
    router.push(`/cards/${card.cardNumber}`)
    return
  }

  instantCards.value.forEach((card) => (card.clicked = false))
  card.clicked = true
  activeCard.value = card
}

onMounted(() => {
  fetchInstantCards()
})
// печать без окна подтверждения
// const printPDF = (pdfUrl) => {
//   const iframe = document.createElement("iframe")
//   iframe.style.display = "none"
//   iframe.src = pdfUrl

//   iframe.onload = () => {
//     iframe.contentWindow.print()
//     setTimeout(() => document.body.removeChild(iframe), 1000) // Удаление iframe после печати
//   }

//   document.body.appendChild(iframe)
// }
</script>
@/modules/cardwork/services/CardService
