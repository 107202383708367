<template>
  <app-page title="Пользователи" double>
    <template #action>
      <div class="tw-w-full tw-px-4 md:tw-pb-4">
        <search-field
          class="tw-w-full"
          v-model="personIIN"
          @submit="searchPerson"
          :loading="isLoading"
        />
      </div>
    </template>
    <template #left>
      <person-data-block v-if="person" :person="person"></person-data-block>
      <div v-else>
        <alert-block
          class="tw-mb-4"
          text="Запросите у
      пользователя документы удостоверяющие личность."
        />
        <recent-search-block
          v-if="RecentSearchService.getPersons().length > 0"
          :items="RecentSearchService.getPersons()"
          @on-item-click="recentSearchPerson"
        ></recent-search-block>
      </div>
    </template>
    <template #right>
      <person-cards-action-block
        v-if="person"
        :person="person"
      ></person-cards-action-block>
      <person-cards-block
        v-if="person"
        :cards="person.cards"
      ></person-cards-block>
    </template>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import SearchField from "@/components/SearchField.vue"
import PersonDataBlock from "@/modules/cardwork/components/PersonDataBlock.vue"
import PersonCardsActionBlock from "@/modules/cardwork/components/PersonCardsActionBlock.vue"
import PersonCardsBlock from "@/modules/cardwork/components/PersonCardsBlock.vue"
import RecentSearchBlock from "@/modules/cardwork/components/RecentSearchBlock.vue"
import RecentSearchService from "@/modules/cardwork/services/RecentSearchService"
import AlertBlock from "@/components/AlertBlock.vue"

import { getPerson } from "@/modules/cardwork/services/PersonService"

import { ref, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"

const router = useRouter()
const route = useRoute()

const personIIN = ref(null)
const person = ref(null)
const isLoading = ref(false)

const searchPerson = async () => {
  isLoading.value = true
  try {
    const personData = await getPerson(personIIN.value)
    person.value = personData
    router.push(`/persons/${person.value.iin}`)
    RecentSearchService.addPerson(person.value.iin)
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
}

const recentSearchPerson = (item) => {
  personIIN.value = item
  searchPerson()
}

onMounted(() => {
  const id = route.params.id
  if (id) {
    personIIN.value = id
    searchPerson()
  }
})
</script>
@/modules/cardwork/services/PersonService
@/modules/cardwork/services/RecentSearchService
