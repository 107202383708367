import axios from "@/plugins/axios.js"
import ScanParser from "@/modules/cardwork/utils/scan-parser"

const getCard = async (cardNumber) => {
  const query = ScanParser.scanCardNumber(cardNumber)
  try {
    const response = await axios.get(`card-info/${query}`, {
      data: {},
    })
    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
const blockCard = async (card) => {
  try {
    const response = await axios.post(`card-block/${card.cardNumber}`, {
      data: {},
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const activateCard = async (card) => {
  try {
    const response = await axios.post(`card-activate/${card.cardNumber}`, {
      data: {},
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const handoverCard = async (card) => {
  try {
    const response = await axios.post(`card-handover`, {
      iin: card.personInfo.iin,
      cardNumber: card.cardNumber,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const unassignCard = async (card) => {
  try {
    const response = await axios.post(`card-unassign/${card.cardNumber}`, {
      data: {},
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const updateStageData = async (card) => {
  try {
    const response = axios.put(
      `card-stage-data/${card.cardNumber}`,
      card.stageData
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getTransactions = async (cardNumber, dateRange) => {
  try {
    const response = await axios.get(`card-transactions/${cardNumber}`, {
      data: {},
      params: dateRange,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getInstantCards = async () => {
  try {
    const response = await axios.get(`instant-cards/list`, { data: {} })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getQRImage = async (qrCode) => {
  try {
    const response = await axios.get(`instant-cards/qr/${qrCode}`, {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    })
    const objectURL = URL.createObjectURL(response.data)
    return Promise.resolve(objectURL)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getPhotoImage = async (photoURI) => {
  try {
    const response = await axios.get(`instant-cards/photo/${photoURI}`, {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    })
    const objectURL = URL.createObjectURL(response.data)
    return Promise.resolve(objectURL)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getImage = async (path) => {
  try {
    const response = await axios.get(`instant-cards/${path}`, {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    })
    const objectURL = URL.createObjectURL(response.data)
    return Promise.resolve(objectURL)
  } catch (error) {
    return Promise.reject(error)
  }
}

const attachPerson = async (data) => {
  try {
    const response = axios.post(`card/attach-person`, data)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const removeBackground = async (data) => {
  try {
    const response = await axios.post(`instant-cards/rembg`, data, {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

const createInstantCard = async (data) => {
  try {
    const response = axios.post(`instant-cards/issue`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export {
  getCard,
  getTransactions,
  getInstantCards,
  getQRImage,
  getPhotoImage,
  getImage,
  blockCard,
  activateCard,
  handoverCard,
  unassignCard,
  attachPerson,
  createInstantCard,
  updateStageData,
  removeBackground,
}
