<template>
  <v-card class="mb-4">
    <v-card-text class="border-b">
      <p class="text-h6 tw-font-medium">Привязанные карты</p>
    </v-card-text>
    <div v-if="cards.length === 0">
      <v-card-text class="border-b">
        <p class="text-body-1 text-grey">Нет привязанных карт</p>
      </v-card-text>
    </div>

    <div v-for="(card, i) in cards" :key="i">
      <v-card-text
        class="cursor-pointer"
        :class="i != cards.length - 1 ? 'border-b' : ''"
      >
        <card-item :card="card" @click="goToCard(card.cardNumber)" />
      </v-card-text>
    </div>
  </v-card>
</template>

<script setup>
import CardItem from "@/modules/cardwork/components/CardItem.vue"
import { defineProps } from "vue"
import { useRouter } from "vue-router"

defineProps({
  cards: {
    type: Object,
    required: true,
  },
})

const router = useRouter()

const goToCard = (cardNumber) => {
  router.push(`/cards/${cardNumber}`)
}
</script>
