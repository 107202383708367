<template>
  <div>
    <h5 class="text-h5 tw-font-medium">Главная страница</h5>
  </div>
</template>

<script>
export default {
  name: "HomePage",
}
</script>
