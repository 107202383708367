export default {
  tolemLogo: {
    type: "image",
    label: "Логотип Tolem",
    x: 67,
    y: 26,
    width: 15,
    height: 15,
  },
  cityLogo: {
    type: "image",
    label: "Логотип города",
    x: 67,
    y: 5,
    width: 15,
    height: 15,
  },
  stampLogo: {
    type: "image",
    label: "Логотип печати",
    x: 30,
    y: 15,
    width: 40,
    height: 40,
  },
  firstName: {
    type: "text",
    label: "Имя",
    x: 32,
    y: 12,
    size: 14,
  },
  lastName: {
    type: "text",
    label: "Фамилия",
    x: 32,
    y: 18,
    size: 14,
  },
  labelExpirationDate: {
    type: "label",
    text: "Жарамдылығы:",
    x: 63.5,
    y: 46,
    size: 6,
  },
  expirationDate: {
    type: "text",
    label: "Дата истечения",
    x: 63.5,
    y: 48.5,
    size: 6,
    color: "gray",
  },
  labelKrcCheck: {
    type: "label",
    text: "ТЕКСЕРУ",
    x: 5,
    y: 48.5,
    size: 16,
  },
  localPhotoUri: {
    type: "image",
    label: "Фото",
    x: 5,
    y: 5,
    width: 22.5,
    height: 30,
  },
}
