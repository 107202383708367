<template>
  <app-page :title="title">
    <template #action>
      <div class="tw-flex-grow"></div>
      <div class="tw-px-4">
        <button
          class="tw-flex md:tw-hidden tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-bg-blue-50 tw-text-blue-600 tw-border tw-border-solid tw-border-blue-300 tw-font-medium tw-rounded-lg hover:tw-brightness-95"
          @click="goToLog"
        >
          Детальный лог
          <span class="material-symbols-outlined">list_alt</span>
        </button>
      </div>
    </template>
    <div class="tw-flex tw-flex-col tw-gap-4 md:tw-pb-16">
      <div
        class="tw-hidden md:tw-flex tw-z-10 tw-fixed tw-bottom-14 tw-left-0 tw-right-0 tw-gap-4 tw-p-4"
      >
        <button
          class="tw-flex md:tw-w-full tw-text-xl tw-justify-center tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-white tw-border tw-border-solid tw-border-blue-800 tw-font-medium tw-rounded-lg hover:tw-brightness-95 tw-shadow-md tw-transform active:tw-scale-95 tw-transition-all"
          @click="goToLog"
        >
          Детальный лог
          <span class="material-symbols-outlined">list_alt</span>
        </button>
      </div>
      <date-picker v-model="dateRange" @update="fetchData" hourly />
      <v-progress-linear
        v-if="isLoading"
        class="rounded"
        height="6"
        color="primary"
        indeterminate
      />

      <div
        v-if="!isLoading"
        class="tw-snap-x tw-flex tw-gap-4 md:tw-overflow-x-auto md:tw-overflow-y-hidden md:no-scrollbar"
      >
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          :data="items"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="count"
          title="Проверки"
          :colors="['#6200EE']"
        />
        <report-chart
          class="tw-w-full md:tw-shrink-0 md:tw-snap-center"
          :data="items"
          :x="isRange ? 'dateConverted' : 'hourConverted'"
          y="userCount"
          title="Сотрудники"
          :colors="['#F9A825']"
        />
      </div>
      <report-table
        v-if="!isLoading"
        :table-data="items"
        :fields="tableFields"
        summaries
        :exportTitle="'Отчет КРС'"
        :exportSubtitle="title"
        :exportDescription="textDateRange"
      />
    </div>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import DatePicker from "@/modules/ticketwork/components/DatePicker.vue"
import ReportChart from "@/modules/ticketwork/components/ReportChart.vue"
import ReportTable from "@/modules/ticketwork/components/ReportTable.vue"

import { ref, computed, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import {
  getReportBusesByDateForBus,
  getReportBusesByHourForBus,
} from "@/modules/ticketwork/services/KrsService"
import { formatDate } from "@/utils/date-utils"
import { convertToHours, convertToHour } from "@/utils/date-utils"
import { useDateRange } from "@/composables/useDateRange.js"
import { usePageStore } from "@/stores/page"

const title = ref("Автобус")
const route = useRoute()
const router = useRouter()
const items = ref([])
const isLoading = ref(true)
const { dateRange, isRange, textDateRange } = useDateRange()
const { changeTitle } = usePageStore()

const fetchDataByDays = async () => {
  try {
    items.value = []
    const id = route.params.id
    if (!id) {
      return
    }
    const from = dateRange.value.from
    const to = dateRange.value.to
    const response = await getReportBusesByDateForBus(from, to, id)
    items.value = response.data.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    )
    items.value = response.data.map((item) => {
      return {
        ...item,
        dateConverted: formatDate(item.date, "DD MMM"),
        succesTicketCount: item.count - item.invalidTicketCount,
      }
    })
  } catch (error) {
    console.log(error)
  }
}

const fetchDataByHours = async () => {
  try {
    items.value = []
    const id = route.params.id
    if (!id) {
      return
    }
    const from = dateRange.value.from
    const response = await getReportBusesByHourForBus(from, id)
    items.value = response.data.sort((a, b) => a.hour - b.hour)
    items.value = response.data.map((item) => {
      return {
        ...item,
        hourConverted: convertToHour(item.hour),
        hoursConverted: convertToHours(item.hour),
        succesTicketCount: item.count - item.invalidTicketCount,
      }
    })
  } catch (error) {
    console.log(error)
  }
}

const fetchData = async (isDayly) => {
  isRange.value = isDayly
  isLoading.value = true
  if (isDayly) {
    await fetchDataByDays()
  } else {
    await fetchDataByHours()
  }
  isLoading.value = false
}

const goToLog = () => {
  const id = route.params.id
  if (id) {
    router.push(
      `/krs/report/buses/log/${id}?from=${dateRange.value.from}&to=${dateRange.value.to}`
    )
  }
}

onMounted(async () => {
  const id = route.params.id
  if (id) {
    title.value = title.value + " — " + id
  }
  changeTitle(title.value)
})

const tableFields = computed(() => ({
  rows: [
    {
      columns: [
        {
          title: isRange.value ? "Дата" : "Время",
          field: isRange.value ? "date" : "hoursConverted",
          rowspan: 1,
          colspan: 1,
          type: isRange.value ? "onlyDate" : "string",
          class: "w-full tw-font-medium tw-text-center ",
        },

        {
          title: "Кол-во сотрудников",
          field: "userCount",
          rowspan: 1,
          colspan: 1,
          type: "number",
          class: "tw-text-right ",
        },
        {
          title: "Кол-во проверок",
          rowspan: 1,
          colspan: 5,
          type: "number",
          columns: [
            {
              title: "Общее",
              field: "count",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right ",
            },
            {
              title: "Успешные",
              field: "succesTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-green-600",
              export: {
                textColor: "#4CAF50",
              },
            },
            {
              title: "Неуспешные",
              field: "invalidTicketCount",
              rowspan: 1,
              colspan: 1,
              type: "number",
              class: "tw-text-right tw-text-red-600",
              export: {
                textColor: "#F44336",
              },
            },
          ],
        },
      ],
    },
  ],
}))
</script>
