<template>
  <app-page title="Транзакции" double>
    <template #header>
      <search-field
        v-model="searchInput"
        @submit="searchTransactions"
        :loading="loading"
      />
    </template>
    <template #left>
      <transaction-data-block
        v-if="transactions.length > 0"
        :transactions="transactions"
      />
      <alert-block v-else class="tw-mb-4" text="Нет данных" />
    </template>
    <template #right>
      <div class="tw-sticky tw-top-4">
        <date-picker-block
          class="mb-4"
          v-model="dateInput"
          @fast="searchTransactions"
        />
      </div>
    </template>
  </app-page>
</template>

<script setup>
import AppPage from "@/components/AppPage.vue"
import AlertBlock from "@/components/AlertBlock.vue"
import SearchField from "@/components/SearchField.vue"
import TransactionDataBlock from "@/modules/cardwork/components/TransactionDataBlock.vue"
import DatePickerBlock from "@/modules/cardwork/components/DatePickerBlock.vue"
import { ref, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { getTransactions } from "@/modules/cardwork/services/CardService"

const route = useRoute()
const router = useRouter()

const searchInput = ref("")
const dateInput = ref("")
const transactions = ref([])
const loading = ref(false)

const searchTransactions = async () => {
  loading.value = true
  try {
    const response = await getTransactions(searchInput.value, dateInput.value)
    transactions.value = response.data
    router.replace({
      name: route.name,
      params: { id: searchInput.value },
    })
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  const id = route.params.id
  if (id) {
    searchInput.value = id
    searchTransactions()
  }
})
</script>
@/modules/cardwork/services/CardService
